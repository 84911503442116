<template>
  <div class="regVipBox">
    <nav-bar :title="$t('开通子账号')" bg-color="#d5ba8d" left-arrow left-color="#000" title-color="#000" />
    <div class="vipBox">
      <div class="topBox">
        <div class="left">
          <p style="width: 100%;height: 20px;"></p>
          <p class="titName">{{ $t('子币余额') }}</p>
          <p class="price">
            <img alt="" class="priceImg" src="@/assets/img/mine11.png">
            <span class="pr">{{ myDate?.sub_coin || 0 }}</span>
          </p>
        </div>
        <img alt="" class="rightImg" src="@/assets/img/mine12.png">
      </div>
      <div class="btmBox">
        <div class="levelBox">
          <p class="checkLevel" @click="fieldShow = true">
            <span class="left">{{ levelTxt || $t("选择等级") }}</span>
            <van-icon color="#fff" name="arrow-down" size="20px" />
          </p>
          <p class="deduct"> {{ $t('需要扣除') }} {{ duct }} </p>
        </div>
        <div class="btnBox">
          <p class="btn" @click="register">{{ $t('开通') }}</p>
        </div>
      </div>
    </div>
    <van-popup v-model="fieldShow" :close-on-click-overlay="false" position="bottom">
      <van-picker :columns="columns" :title="$t('选择等级')" show-toolbar @cancel="onCancel" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        myDate: {},
        levelTxt: "",
        level_id: "",
        duct: 0,
        fieldShow: false,
        columns: [],
      }
    },
    methods: {
      // 获取会员信息
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          loading: true,
          success: (res) => {
            this.myDate = res.data
          },
          tip: () => {
          }
        })
      },
      // 获取会员等级信息
      getLevelList() {
        this.$get({
          url: `/memberLevel/getLevelList`,
          loading: true,
          success: (res) => {
            res.data.map(item => {
              this.columns.push({
                id: item.id,
                text: item.level_name,
                price: item.amount,
              })
            })
          },
          tip: () => {
          }
        })
      },
      // 注册会员
      register() {
        if (!this.level_id) return this.$toast(this.$t("请选择等级"))
        this.$post({
          url: `/member/subReg`,
          loading: true,
          data: {
            level_id: this.level_id,
          },
          success: (res) => {
            this.$toast({
              message: res.message,
              onClose: () => {
                this.getMemberInfo()
              }
            })

          },
          tip: () => {
          }
        })
      },
      onConfirm(e) {
        this.levelTxt = e.text
        this.level_id = e.id
        this.duct = e.price
        this.fieldShow = false
      },
      onCancel() {
        this.fieldShow = false
        this.positionShow = false
      },
    },
    created() {
      this.getMemberInfo()
      this.getLevelList()
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .regVipBox {
    min-height: 100vh;
    background-color: #2c2e2f;
    width: 100%;
    .topBox {
      padding: 0 20px 50px;
      background-color: #d5ba8d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 50%;
        .titName {
          color: #7f6338;
        }
        .price {
          display: flex;
          align-items: center;
          .priceImg {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 5px;
          }
          .pr {
            font-size: 20px;
            font-weight: bold;
            color: #583f20;
          }
        }
      }
      .rightImg {
        width: 160px;
        height: 160px;
      }
    }
    .btmBox {
      padding: 0 10px 30px;
      background-color: #2c2e2f;
      .levelBox {
        background-color: #343638;
        padding: 20px;
        border-radius: 10px;
        transform: translateY(-50px);
        .checkLevel {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #434547;
          padding: 15px 10px;
          border-radius: 8px;
          .left {
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
          }
        }
        .deduct {
          color: #a29f99;
          font-size: 12px;
          margin: 10px 0;
        }
        ::v-deep .van-field {
          background-color: #343638;
          padding-left: 0;
          padding-right: 0;
          .van-field__label {
            color: #ffffff;
          }
          .van-field__value {
            .van-field__control {
              color: #ffffff;
            }
          }
        }
        .line {
          display: block;
          width: 100%;
          height: 1px;
          background-color: #434547;
        }
      }
      .btnBox {
        padding: 10px 15px;
        .btn {
          background-color: #b99b66;
          color: #ffffff;
          border-radius: 10px;
          padding: 10px 0;
          text-align: center;
        }
      }
    }
  }
</style>
