<template>
  <div class="withdrawBox">
    <nav-bar :right-text="$t('提现记录')" :title="$t('提现')" bg-color="transparent" left-arrow left-color="#fff"
      right-color="#fff" title-color="#fff" @click-right="router('/withList')" />
    <div class="box">
      <div class="topBalance">
        <p class="title">{{ $t('权益币余额') }}</p>
        <p class="price">{{ price }}</p>
      </div>
      <!--      <div class="checkBox">-->
      <!--        <van-field v-model="field" input-align="right" label="提现至" placeholder="请选择" readonly right-icon="arrow" @click="choice"/>-->
      <!--      </div>-->
      <div class="convertBox">
        <p class="title">{{ $t('提现数量') }}</p>
        <van-field v-model="value" :border="false" :placeholder="$t('请输入提现数量')" />
        <p class="costBox">
          <span>{{ $t('手续费') }}：{{ withdraw_fee }}%</span>
          <span>{{ $t('到账数量') }}：{{ exC }}</span>
        </p>
        <van-field class="passbox" v-model="password" clearable type="password" :border="false"
          :placeholder="$t('请输入交易密码')" maxlength="6" />
      </div>
      <div class="avow" v-html="withdraw_info" />
      <div class="btnBox">
        <p class="btn" @click="withdrawDo()">{{ $t('提现') }}</p>
      </div>
      <!--      <van-popup v-model="fieldShow" :close-on-click-overlay="false" position="bottom">-->
      <!--        <van-picker :columns="columns" show-toolbar title="提现地址" @cancel="onCancel" @confirm="onConfirm"/>-->
      <!--      </van-popup>-->
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        price: "",
        value: "",
        field: "",
        fieldShow: false,
        columns: [],
        withdraw_info: "",
        withdraw_fee: "",
        withdraw_open: "",
        password: '',
      }
    },
    computed: {
      exC: {
        get() {
          return this.value * (1 - (this.withdraw_fee / 100))
        },
        set() { }
      }
    },
    watch: {},
    methods: {
      // // 获取支付方式
      // getPayInfo() {
      //   this.$get({
      //     url: `/memberPay/getPayInfo`,
      //     loading: true,
      //     success: (res) => {
      //       if (!res.data) return this.$toast("请先绑定收款方式")
      //       console.log(res)
      //     },
      //     tip: () => {
      //     }
      //   })
      // },
      // 获取提现配置
      getConfig() {
        this.$get({
          url: `/withdraw/getConfig`,
          loading: true,
          success: (res) => {
            // this.getPayInfo()
            this.withdraw_info = res.data.withdraw_info
            this.withdraw_fee = res.data.withdraw_fee
            this.withdraw_open = res.data.withdraw_open
            this.getMemberInfo()
          },
          tip: () => {
          }
        })
      },
      // 获取信息
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          loading: true,
          success: (res) => {
            this.price = res.data.equity
          },
          tip: () => {
          }
        })
      },
      withdrawDo() {
        if (!this.value) return this.$toast(this.$t('请输入提现数量'))
        if (!this.password) return this.$toast(this.$t('请输入交易密码'))
        this.$post({
          url: `/withdraw/withdrawDo`,
          loading: true,
          data: {
            amount: this.value,
            password: this.password,
          },
          success: (res) => {
            this.$toast({
              message: res.message,
              onClose: () => {
                this.value = ""
                this.getMemberInfo()
              }
            })

          },
          tip: () => {
          }
        })
      },
      // choice() {
      //   if (this.columns.length <= 0) return this.$toast("请先绑定收款方式")
      //   this.fieldShow = true
      // },
      // onConfirm(e) {
      //   this.field = e.text
      //   this.fieldShow = false
      // },
      // onCancel(e) {
      //   this.fieldShow = false
      // },
    },
    created() {
      // this.price = this.$route.query.price
      this.getConfig()
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .withdrawBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px;
    .box {
      .topBalance {
        background-color: #343638;
        border-radius: 8px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        gap: 10px 0;
        .title {
          font-weight: bold;
          font-size: 12px;
        }
        .price {
          font-weight: bold;
          font-size: 20px;
        }
      }
      .checkBox {
        margin-top: 15px;
        border-radius: 8px;
        overflow: hidden;
        ::v-deep .van-field {
          background-color: #343638;
          border-radius: 8px;
          .van-field__label {
            color: #ffffff;
          }
          .van-field__value {
            .van-field__control {
              color: #ffffff;
            }
          }
        }
      }
      .convertBox {
        padding: 15px;
        background-color: #343638;
        border-radius: 8px;
        margin-top: 15px;
        color: #ffffff;
        .title {}
        ::v-deep .van-field {
          padding: 0 5px;
          background-color: #343638;
          border-bottom: 1px solid #999999;
          margin-top: 30px;
          .van-field__label {
            color: #ffffff;
          }
          .van-field__value {
            .van-field__control {
              color: #ffffff;
            }
          }
        }
        .passbox {
          margin-top: 15px;
          padding: 5px 0;
          border-bottom: none;
        }
        .costBox {
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .avow {
        color: #ffffff;
        margin-top: 10px;
      }
      .btnBox {
        padding: 0 15px;
        .btn {
          color: #ffffff;
          background-color: #ba9c68;
          border-radius: 8px;
          padding: 10px 0;
          text-align: center;
          margin-top: 100px;
        }
      }
    }
  }
</style>
