<template>
  <div class="transferBox">
    <nav-bar :right-text="$t('转赠记录')" :title="$t('转赠')" bg-color="transparent" left-arrow left-color="#fff"
      right-color="#fff" title-color="#fff" @click-right="router(`/transferList?type=${type}`)" />
    <div class="box">
      <div class="topBalance" v-if="type == 1">
        <p class="title">{{ $t('注册币余额') }}</p>
        <p class="price">{{ info.coin || 0 }}</p>
      </div>
      <div class="topBalance" v-if="type == 2">
        <p class="title">{{ $t('子币余额') }}</p>
        <p class="price">{{ info.sub_coin || 0 }}</p>
      </div>
      <div class="checkBox">
        <van-field v-model="field" :label="$t('转赠人')" :placeholder="$t('请输入转赠人编号')" input-align="right" />
      </div>
      <div class="convertBox">
        <p class="title">{{ $t('转赠数量') }}</p>
        <van-field v-model="value" :border="false" :placeholder="$t('请输入转赠数量')" />
        <p class="costBox">
          <span v-if="type == 1">{{ $t('手续费') }}：{{ transfer_fee }}%</span>
          <span>{{ $t('扣除数量') }}：{{ ten }}</span>
        </p>
        <van-field class="passbox" v-model="password" clearable type="password" :border="false"
          :placeholder="$t('请输入交易密码')" maxlength="6" />
      </div>
      <div class="avow" v-html="transfer_info" />
      <div class="btnBox">
        <p class="btn" @click="transferDo()">{{ $t('转赠') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        value: "",
        field: "",
        info: {},
        transfer_fee: "",
        transfer_info: "",
        transfer_open: "",
        password: '',
        type: this.urlQuery().type,//1 注册币 2 子币
      }
    },
    computed: {
      ten: {
        get() {
          if (this.type == 1) {
            return (this.value * (1 + (Number(this.transfer_fee) / 100)))
          } else {
            return this.value
          }
        },
        set() {
        }
      }
    },
    watch: {},
    methods: {
      // 获取转赠配置
      getConfig() {
        this.$get({
          url: `/transfer/getConfig`,
          loading: true,
          success: (res) => {
            this.transfer_fee = res.data.transfer_fee
            this.transfer_info = res.data.transfer_info
            this.transfer_open = res.data.transfer_open
            this.getMemberInfo()
          },
          tip: () => {
          }
        })
      },
      // 获取信息
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          loading: true,
          success: (res) => {
            this.info = res.data
          },
          tip: () => {
          }
        })
      },
      // 转赠
      transferDo() {
        if (!this.field) return this.$toast(this.$t('请输入转赠人编号'))
        if (!this.value) return this.$toast(this.$t('请输入转增数量'))
        if (!this.password) return this.$toast(this.$t('请输入交易密码'))
        this.$post({
          url: `/transfer/do`,
          loading: true,
          data: {
            amount: this.value,
            show_id: this.field,
            password: this.password,
            type: this.type,
          },
          success: (res) => {
            this.value = ""
            this.$toast({
              message: res.message, onClose: () => {
                this.getMemberInfo()
              }
            })

          },
          tip: () => {
          }
        })
      },
    },
    created() {
      // this.price = this.$route.query.price
      this.getConfig()
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .transferBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px;
    .box {
      .topBalance {
        background-color: #343638;
        border-radius: 8px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        gap: 10px 0;
        .title {
          font-weight: bold;
          font-size: 12px;
        }
        .price {
          font-weight: bold;
          font-size: 20px;
        }
      }
      .checkBox {
        margin-top: 15px;
        border-radius: 8px;
        overflow: hidden;
        ::v-deep .van-field {
          background-color: #343638;
          border-radius: 8px;
          .van-field__label {
            color: #ffffff;
          }
          .van-field__value {
            .van-field__control {
              color: #ffffff;
            }
          }
        }
      }
      .convertBox {
        padding: 15px;
        background-color: #343638;
        border-radius: 8px;
        margin-top: 15px;
        color: #ffffff;
        .title {}
        ::v-deep .van-field {
          padding: 0 5px;
          background-color: #343638;
          border-bottom: 1px solid #999999;
          margin-top: 30px;
          .van-field__label {
            color: #ffffff;
          }
          .van-field__value {
            .van-field__control {
              color: #ffffff;
            }
          }
        }
        .passbox {
          margin-top: 15px;
          padding: 5px 0;
          border-bottom: none;
        }
        .costBox {
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .avow {
        margin-top: 10px;
        color: #ffffff;
      }
      .btnBox {
        padding: 0 15px;
        .btn {
          color: #ffffff;
          background-color: #ba9c68;
          border-radius: 8px;
          padding: 10px 0;
          text-align: center;
          margin-top: 100px;
        }
      }
    }
  }
</style>
