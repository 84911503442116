<template>
  <div class='indexBox'>
    <nav-bar :title="$t('首页')" bg-color="#2c2e2f" title-color="#bdb299" />
    <div class="allFund">
      <div>
        <p class="txt">{{ $t('平台总权益股数') }}</p>
        <p class="num">{{ balance_total }}</p>
      </div>
      <div>
        <p class="txt">{{ $t('累计销毁数量') }}</p>
        <p class="num">{{ destroy_num }}</p>
      </div>
    </div>
    <div class="notice">
      <img alt="" class="leftImg" src="@/assets/img/home5.png">
      <van-swipe :autoplay="3000" :show-indicators="false" class="notice-swipe" vertical>
        <van-swipe-item v-for="(item, index) in noticeList" :key="index" @click="goToNoticeDetail(item.id)">
          {{ item.title }}
        </van-swipe-item>
      </van-swipe>
      <van-icon color="#fff" name="arrow" @click="goToNoticeList()" />
    </div>
    <div class="dataShow">
      <div class="titleTxt">
        <img alt="" class="titleImg" src="@/assets/img/home6.png">
        <p class="realTimeDate">
          <span class="top">{{ $t('实时数据展示') }}</span>
        </p>
        <img alt="" class="titleImg" src="@/assets/img/home7.png">
      </div>
      <div class="numBox">
        <div class="num left">
          <span class="topTxt" @click="router('waitList')">{{ $t('排队人数') }}</span>
          <span class="btmNum" @click="router('waitList')">{{ wait_num }}</span>
          <span class="topTxt">{{ $t('排队总金额') }}</span>
          <span class="btmNum">{{ wait_money }}</span>
        </div>
        <div class="num right" @click="router('bonus')">
          <span class="topTxt">{{ $t('奖金池金额') }}</span>
          <span class="btmNum">{{ bonus_pool }}</span>
        </div>
      </div>
    </div>
    <div class="trendBox">
      <p class="titleTxt">{{ $t('价格趋势表') }}</p>
      <p class="nowPrice">
        <span class="left">
          <img alt="" class="leftImg" src="@/assets/img/home4.png">
          {{ $t('当前价格') }}
        </span>
        <span class="right">${{ balance_price }}</span>
      </p>
      <div id="echartsBox" class="echartsBox" />
      <div class="btnBox">
        <span :class="{ check: active === 0 }" class="btn leftB" @click="cutEcharts(0)">{{ $t('近7天') }}</span>
        <!--        <span :class="{check:active===1}" class="btn centerB" @click="cutEcharts(1)">{{ $t('近1月') }}</span>-->
        <span :class="{ check: active === 1 }" class="btn rightB" @click="cutEcharts(1)">{{ $t('近1月') }}</span>
        <!--        <span :class="{check:active===2}" class="btn rightB" @click="cutEcharts(2)">{{ $t('近3月') }}</span>-->
      </div>
    </div>
  </div>
</template>
<script>
  import * as echarts from "echarts";

  export default {
    props: {},
    data() {
      return {
        charts: "",
        active: 0,
        xAxisDate: [],
        optionData: [],
        legendName: [],
        seriesName: this.$t("近7天"),
        balance_price: "0.00",
        balance_total: "0.00",
        bonus_pool: "0.00",
        wait_num: "0",
        wait_money: "0.00",
        destroy_num: "0.00",
        noticeList: [],
      }
    },
    created() {
      this.getHomeData()
    },
    methods: {
      // 获取首页数据
      getHomeData() {
        this.$get({
          url: `/index/getHomeData`,
          // loading: true,
          success: (res) => {
            this.balance_price = res.data.balance_price
            this.balance_total = res.data.balance_total
            this.bonus_pool = res.data.bonus_pool
            this.wait_num = res.data.wait_num
            this.wait_money = res.data.wait_money
            this.destroy_num = res.data.destroy_num
            this.getNotice()
          },
          tip: () => {
          }
        })
      },
      // 获取首页公告列表
      getNotice() {
        this.$get({
          url: `/notice/list?page=1`,
          // loading: true,
          success: (res) => {
            this.noticeList = res.data.data
            this.getPriceLog()
          },
          tip: () => {
          }
        })
      },
      // 获取价格趋势表
      getPriceLog() {
        this.$get({
          url: `/priceLog/logList`,
          data: {
            type: [1, 2, 3][this.active]
          },
          // loading: true,
          success: (res) => {
            this.xAxisDate = res.data.days
            this.optionData = res.data.prices
            this.$nextTick(() => {
              this.drawLine("echartsBox")
            })
          },
          tip: () => {
          }
        })
      },
      goToNoticeList() {
        this.$router.push('/noticeList')
      },
      goToNoticeDetail(id) {
        this.$router.push(`/noticeDetail?id=${id}`)
      },
      drawLine(id) {
        this.charts = echarts.init(document.getElementById(id))
        this.charts.setOption({
          tooltip: {
            show: false,
            trigger: 'axis'
          },
          grid: {
            top: '5%',
            left: '0%',
            right: '6%',
            bottom: '5%',
            containLabel: true
          },
          // toolbox: {
          //   feature: {
          //     // saveAsImage: {} //保存为图片
          //   }
          // },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              color: '#fff',
              // interval:(val,inx)=>{
              //   return val % 5 === 0
              // }
              // interval:0,
              // formatter: (value)=>{
              //   return value.split('').join('\n')
              // }
            },
            axisTick: {
              alignWithLabel: true //保证刻度线和标签对齐
            },
            data: this.xAxisDate //x坐标的名称
          },
          yAxis: {
            type: 'value',
            boundaryGap: true,
            splitNumber: 10, //纵坐标数
            interval: 0.02, //强制设置坐标轴分割间隔
            min: 0.2,
            max: 0.4,
            axisLabel: {
              color: '#fff',
              formatter: "{value}U"
            }
          },
          // dataZoom: [
          //   {
          //     xAxisIndex: [0], //这里是从X轴的0刻度开始
          //     show: true, //是否显示滑动条，不影响使用
          //     type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
          //     startValue: 0, // 从头开始。
          //     endValue: 5, // 一次性展示5个。
          //
          //   },
          // ],
          series: [{
            name: this.seriesName,
            type: 'line', //折线图line;柱形图bar;饼图pie
            // stack: '总量',
            areaStyle: {
              //显示区域颜色---渐变效果
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#e5d3b2' // 0% 处的颜色
                }, {
                  offset: 1, color: '#ffffff' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              },
            },
            itemStyle: {
              color: '#e5d3b2', //改变折线点的颜色
              lineStyle: {
                color: '#e5d3b2' //改变折线颜色
              }
            },
            data: this.optionData
          }]
        })
      },
      cutEcharts(num) {
        this.active = num
        this.seriesName = [this.$t("近7天"), this.$t("近1月"), this.$t("近3月")][num]
        this.getPriceLog()
      },
    },
  }
</script>
<style lang="less" scoped>
  .indexBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px 15px 70px;
    .allFund {
      width: 100%;
      min-height: 120px;
      background-image: url("../../assets/img/home1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 8px;
      padding: 20px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #5d432a;
      .num {
        font-size: 24px;
        font-weight: 550;
      }
      >div {
        &:nth-child(2) {
          margin-top: 10px;
        }
      }
    }
    .notice {
      margin-top: 20px;
      background-color: #343638;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      ::v-deep .notice-swipe {
        width: 100%;
        height: 30px;
        color: #ffffff;
        .van-swipe-item {
          display: flex;
          align-items: center;
        }
      }
      .leftImg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      .rightImg {
        width: 10px;
        height: 15px;
        margin-left: 10px;
      }
    }
    .dataShow {
      background: linear-gradient(134deg, #DBC397 0%, #BA9A66 100%);
      border-radius: 10px;
      padding: 20px 10px;
      margin-top: 20px;
      .titleTxt {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 10px;
        .titleImg {
          width: 24px;
          height: 24px;
        }
        .realTimeDate {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #543b1b;
          //background-image: url("../../assets/img/home4.png");
          .top {
            font-weight: bold;
            font-size: 25px;
          }
          .btm {
            font-size: 16px;
          }
        }
      }
      .numBox {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        margin-top: 20px;
        .num {
          width: 47%;
          //background-color: #f4e4c9;
          padding: 15px 15px 20px;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          color: #604c30;
          .topTxt {
            font-size: 14px;
            font-weight: bold;
          }
          .btmNum {
            font-size: 18px;
            font-weight: bold;
          }
        }
        .left {
          background-image: url("../../assets/img/home2.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          padding: 2px 15px;
          .topTxt {
            font-size: 14px;
            font-weight: bold;
          }
          .btmNum {
            font-size: 16px;
            font-weight: bold;
          }
        }
        .right {
          background-image: url("../../assets/img/home3.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .trendBox {
      margin-top: 20px;
      background-color: #343638;
      padding: 20px;
      border-radius: 10px;
      .titleTxt {
        color: #cec3ab;
        font-size: 16px;
        font-weight: bold;
      }
      .nowPrice {
        background-color: #dbc396;
        padding: 7px 20px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #725b33;
        font-weight: bold;
        border-radius: 6px;
        margin-top: 10px;
        .left {
          display: flex;
          align-items: center;
          gap: 0 10px;
          .leftImg {
            width: 22px;
            height: 22px;
          }
        }
      }
      .echartsBox {
        width: 100%;
        height: 300px;
        background-color: transparent;
        margin-top: 15px;
      }
      .btnBox {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
          padding: 5px 15px;
          background-color: transparent;
          color: #7c7c7c;
          border: 1px solid #b6b7bb;
          &.check {
            color: #d7c7a8;
            background-color: #424243;
          }
        }
        .leftB {
          border-radius: 100px 0 0 100px;
        }
        .centerB {
          border-left: none;
          border-right: none;
        }
        .rightB {
          border-radius: 0 100px 100px 0;
        }
      }
    }
  }
</style>
