<template>
  <div class='loginBox'>
    <p class="logoBox">
      <img alt="" class="logo" src="@/assets/img/logo.png">
    </p>
    <div class="inputBox">
      <van-field v-model="text" :border="false" :label="$t('邮箱')" :placeholder="$t('请输入邮箱')" />
      <van-field v-model="password" :border="false" :label="$t('密码')" :placeholder="$t('请输入登录密码')" type="password" />
      <van-field v-model="localeTxt" :border="false" :label="$t('语言')" :placeholder="$t('请选择语言')" readonly
        right-icon="arrow-down" @click="checkLocale" />
    </div>
    <div class="btn" @click="loginBtn">{{ $t('登录') }}</div>
    <div class="operate">
      <span class="forgotPass" @click="router('/download')">{{ $t('下载') }}</span>
      <span class="forgotPass" @click="router('/password')">{{ $t('忘记密码') }}</span>
    </div>
    <div class="protocol">
      <img v-show="!check" alt="" class="radioImg" src="@/assets/img/check2.png" @click="check = !check">
      <img v-show="check" alt="" class="radioImg" src="@/assets/img/check1.png" @click="check = !check">
      <div class="txt">
        {{ $t('已阅读并同意') }}
        <span class="tc1" @click="router('/agreement', { type: 1 })">{{ $t('服务协议') }}</span>
        {{ $t('和') }}
        <span class="tc1" @click="router('/agreement', { type: 2 })">{{ $t('隐私政策') }}</span>
      </div>
    </div>
    <van-popup v-model="fieldShow" :close-on-click-overlay="false" position="bottom">
      <van-picker :columns="columns" :title="$t('选择语言')" show-toolbar @cancel="onCancel" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        text: "",
        password: "",
        check: false,
        locale: "",
        localeTxt: "",
        fieldShow: false,
        columns: [
          { id: 0, text: this.$t("中文"), locale: "zh" },
          { id: 1, text: this.$t("英文"), locale: "en" },
        ]
      }
    },
    computed: {},
    watch: {},
    methods: {
      loginBtn() {
        if (!this.text) return this.$toast(this.$t("请输入邮箱"))
        if (!this.password) return this.$toast(this.$t("请输入登录密码"))
        if (!this.check) return this.$toast(this.$t("请同意服务协议与隐私政策"))
        this.$post({
          url: "/login",
          loading: true,
          noToken: true,
          data: {
            account: this.text,
            password: this.password,
          },
          success: (res) => {
            this.local.set("token", res.data.token)
            this.toast({
              message: this.$t(this.$t('登录成功')),
              onClose: () => {
                this.goReplace("/")
              },
            })
          },
          tip: () => {
          },
        })
      },
      checkLocale() {
        this.fieldShow = true
      },
      onConfirm(e) {
        this.localeTxt = e.text
        this.locale = e.locale
        this.fieldShow = false
        this.local.set("lang", e.locale)
        this.$router.go(0)
        // window.location.reload()
      },
      onCancel(e) {
        this.fieldShow = false
      },
    },
    created() {
      this.locale = this.local.get("lang") ? this.local.get("lang") : "zh"
      this.localeTxt = this.locale ? this.locale == "zh" ? this.$t("中文（繁体）") : this.$t("英文") : ""
      this.local.set("lang", this.locale)
    },
    mounted() {
    },
    beforeCreate() {
    }, //生命周期 - 创建之前
    beforeMount() {
    }, //生命周期 - 挂载之前
    beforeUpdate() {
    }, //生命周期 - 更新之前
    updated() {
    }, //生命周期 - 更新之后
    beforeDestroy() {
    }, //生命周期 - 销毁之前
    destroyed() {
    }, //生命周期 - 销毁完成
    activated() {
    }, //如果页面有keep-alive缓存功能，这个函数会触发
  }
</script>
<style lang='less' scoped>
  .loginBox {
    width: 100%;
    min-height: 100vh;
    padding: 30px;
    background-image: url("../../../assets/img/login.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .logoBox {
      text-align: center;
      margin-bottom: 20px;
      .logo {
        width: 122px;
        height: 122px;
      }
    }
    .inputBox {
      display: flex;
      flex-direction: column;
      gap: 20px 0;
      ::v-deep .van-field {
        background-color: #000000;
        border-radius: 7px;
        .van-field__label {
          color: #ffffff;
        }
        .van-field__value {
          .van-field__body {
            .van-field__control {
              color: #ffffff;
            }
          }
        }
      }
    }
    .btn {
      padding: 10px 0;
      background-color: #cbac7b;
      border-radius: 8px;
      margin-top: 60px;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
    }
    .operate {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      .forgotPass {
        font-size: 12px;
        color: #999999;
      }
    }
    .protocol {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 10px;
      margin-top: 100px;
      .radioImg {
        width: 15px;
        height: 15px;
      }
      .txt {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: #999999;
        .tc1 {
          margin: 0 5px;
          color: #adadad;
        }
      }
    }
  }
</style>
