module.exports = {
  // 登录页
  "邮箱": "郵箱",
  "密码": "密碼",
  "登录": "登入",
  "忘记密码": "忘記密碼",
  "语言": "語言",
  "已阅读并同意": "已閱讀並同意",
  "服务协议": "服務協定",
  "和": "和",
  "隐私政策": "隱私政策",
  "用户协议": "使用者協定",
  "请输入登录邮箱": "請輸入登入郵箱",
  "请输入登录密码": "請輸入登入密碼",
  "选择语言": "選擇語言",
  // 忘记密码页
  "验证码": "驗證碼",
  "获取": "獲取",
  "后获取": "後獲取",
  "新密码": "新密碼",
  "请输入邮箱": "請輸入郵箱",
  "请输入验证码": "請輸入驗證碼",
  "请输入新密码": "請輸入新密碼",
  "请重复新密码": "請重複新密碼",
  "": "",
  // 下载页
  "下载": "下載",
  // tabbar 标签栏
  "首页": "首頁",
  "交易大厅": "交易大廳",
  "游戏": "遊戲",
  "团队": "團隊",
  "我的": "我的",
  // 首页
  "平台总权益股数": "平臺總權益股數",
  "实时数据展示": "即時資料展示",
  "股权总量": "股權總量",
  "奖金池金额": "獎金池金額",
  "价格趋势表": "價格趨勢錶",
  "当前价格": "當前價格",
  "近7天": "近7天",
  "近1月": "近1月",
  "近3月": "近3月",
  "公告": "公告",
  "公告详情": "公告詳情",
  "排队人数": "排隊人數",
  "排队总金额": "排隊總金額",
  // 交易大厅页
  "权益币价格": "權益幣價格",
  "买单大厅": "買單大廳",
  "卖单大厅": "賣單大廳",
  "发布买单": "發佈買單",
  "钱包地址": "錢包地址",
  "发布数量": "發佈數量",
  "发布时间": "發佈時間",
  "价值": "價值",
  "我要匹配": "我要匹配",
  "匹配": "匹配",
  "数量": "數量",
  "取消": "取消",
  "请输入数量": "請輸入數量",
  "购买数量": "購買數量",
  "请输入购买数量": "請輸入購買數量",
  "需要支付": "需要支付",
  "提交": "提交",
  "发布卖单": "發佈賣單",
  "到账": "到賬",
  "手续费": "手續費",
  "出售数量": "出售數量",
  "支出数量": "支出數量",
  // 游戏页
  "游戏板块建设中": "遊戲板塊建設中",
  // 团队页
  "星": "星",
  "我的直推人数": "我的直推人數",
  "我的团队人数": "我的團隊人數",
  "我的推荐人": "我的推薦人",
  "编号": "編號",
  "直推列表": "直推清單",
  "累计贡献业绩": "累計貢獻業績",
  "安置网络": "安置網絡",
  "会员编号": "會員編號",
  "会员邮箱": "會員郵箱",
  "会员等级": "會員等級",
  "会员业绩": "會員業績",
  "非直推用户不可查看": "非直推用戶不可查看",
  // 我的页
  "我的资产": "我的資產",
  "股权余额": "股權餘額",
  "权益币余额": "權益幣餘額",
  "注册币余额": "注册幣餘額",
  "权益等级升级": "權益等級陞級",
  "交易记录": "交易記錄",
  "注册会员": "註冊會員",
  "语言切换": "語言切換",
  "关于我们": "關於我們",
  "设置": "設定",
  "个人信息": "個人資訊",
  "头像": "頭像",
  "昵称": "昵稱",
  "登录密码": "登入密碼",
  "修改昵称": "修改昵稱",
  "修改登录密码": "修改登入密碼",
  "获取验证码": "獲取驗證碼",
  "重复密码": "重複密碼",
  "收益": "收益",
  "铸造币余额": "鑄造幣餘額",
  "兑换": "兌換",
  "提现": "提現",
  "兑换注册币数量": "兌換注册幣數量",
  "到账数量": "到賬數量",
  "兑换记录": "兌換記錄",
  "提现记录": "提現記錄",
  "提现至": "提現至",
  "请选择": "請選擇",
  "提现数量": "提現數量",
  "到账金额": "到賬金額",
  "审核中": "稽核中",
  "已到帐": "已到帳",
  "提现失败": "提現失敗",
  "您的审核失败": "您的稽核失敗",
  "注册币余额钱包": "注册幣餘額錢包",
  "转赠": "轉贈",
  "转赠记录": "轉贈記錄",
  "转赠人": "轉贈人",
  "请输入转赠人编号": "請輸入轉贈人編號",
  "转赠数量": "轉贈數量",
  "转赠明细": "轉贈明細",
  "权益登记升级": "權益登記陞級",
  "悦享": "悅享",
  "可升级": "可升級",
  "需": "需",
  "注册币": "注册幣",
  "立即升级": "立即陞級",
  "买单": "買單",
  "卖单": "賣單",
  "待匹配": "待匹配",
  "待付款": "待付款",
  "待确认": "待確認",
  "已完成": "已完成",
  "已投诉": "已投訴",
  "已取消": "已取消",
  "单价": "單價",
  "交易地址": "交易地址",
  "交易时间": "交易時間",
  "取消匹配": "取消匹配",
  "上传凭证": "上傳憑證",
  "获得": "獲得",
  "注册记录": "注册記錄",
  "我的注册币": "我的注册幣",
  "选择等级": "選擇等級",
  "需要扣除": "需要扣除",
  "请输入会员邮箱地址": "請輸入會員郵箱地址",
  "推荐人": "推薦人",
  "请输入推荐人编号": "請輸入推薦人編號",
  "安置人": "安置人",
  "安置位置": "安置位置",
  "请输入安置人编号": "請輸入安置人編號",
  "扣除币数": "扣除幣數",
  "安置人编号": "安置人編號",
  "推荐人编号": "推薦人編號",
  "注册时间": "註冊時間",
  "中文": "中文",
  "英文": "英文",
  "支付设置": "支付設定",
  "支付平台": "支付平臺",
  "微信支付": "微信支付",
  "支付宝支付": "支付寶支付",
  "删除": "删除",
  "添加": "添加",
  "银行卡": "銀行卡",
  "添加银行卡": "添加銀行卡",
  "绑定支付宝": "綁定支付寶",
  "绑定微信": "綁定微信",
  "姓名": "姓名",
  "请输入姓名": "請輸入姓名",
  "账号": "帳號",
  "请输入账号": "請輸入帳號",
  "收款二维码": "收款二維碼",
  "请上传收款二维码": "請上傳收款二維碼",
  "绑定": "綁定",
  "绑定银行卡": "綁定銀行卡",
  "卡号": "卡號",
  "请输入卡号": "請輸入卡號",
  "开户行": "開戶行",
  "请输入开户行": "請輸入開戶行",
  "保存": "保存",
  "没有更多了": "沒有更多了",
  "开通": "開通",
  "左区": "左區",
  "右区": "右區",
  "退出登录": "登出",
  "拆分记录": "折開記錄",
  "拆分次数": "折開次數",
  "拆分倍数": "折開倍數",
  "拆分时间": "折開時間",
  "第": "第",
  "次拆分": "次折開",
  "倍": "倍",
  "联系方式": "聯繫方式",
  "请输入联系方式": "請輸入聯繫方式",
  "修改联系方式": "修改聯繫方式",
  // 提示信息
  "请重复输入密码": "請重複輸入密碼",
  "两次密码不同": "兩次密碼不同",
  "请输入手机号": "請輸入手機號",
  "请输入正确格式的手机号": "請輸入正確格式的手機號",
  "发送成功": "發送成功",
  "请同意服务协议与隐私政策": "請同意服務協定與隱私政策",
  "请输入确认登录密码": "請輸入確認登入密碼",
  "两次输入的登录密码不一致": "兩次輸入的登入密碼不一致",
  "请输入交易密码": "請輸入交易密碼",
  "交易密码为6位数字格式": "交易密碼為6比特數位格式",
  "请输入确认交易密码": "請輸入確認交易密碼",
  "两次输入的交易密码不一致": "兩次輸入的交易密碼不一致",
  "请输入邀请码": "請輸入邀請碼",
  "请勾选用户注册协议": "請勾選用戶註冊協定",
  "请输入昵称": "請輸入昵稱",
  "兑换暂未开放": "兌換暫未開放",
  "请输入权益币数量": "請輸入權益幣數量",
  "请选择注册等级": "請選擇注册等級",
  "请输入会员邮箱": "請輸入會員郵箱",
  "请选择安置位置": "請選擇安置位置",
  "请输入申诉理由": "請輸入申訴理由",
  "请输入转增数量": "請輸入轉增數量",
  "请输入提现数量": "請輸入提現數量",
  "登录成功": "登入成功",
  "验证码获取成功": "驗證碼獲取成功",
  "复制成功": "複製成功",
  "复制失败": "複製失敗",
  "请选择语言": "請選擇語言",
  "累计销毁数量": "累計銷毀數量",
  "请输入匹配数量": "請輸入匹配數量",
  "请输入出售数量": "請輸入出售數量",
  "请输入卖出数量": "請輸入賣出數量",
  "合计支出": "合計支出",
  "奖金池分红": "獎金池分紅",
  "等待排队配股": "等待排隊配股",
  "币安链地址": "幣安鏈地址",
  "请输入币安链钱包地址": "請輸入幣安鏈錢包地址",
  "交易密码": "交易密碼",
  "修改交易密码": "修改交易密碼",
  "请输入重复密码": "請輸入重複密碼",
  "客服": "客服",
  "记录": "記錄",
  "请输入留言内容": "請輸入留言內容",
  "提交成功": "提交成功",
  "反馈记录": "迴響記錄",
  "回复时间": "回復時間",
  "回复": "回復",
  "搜索": "搜索",
  "请输入搜索ID": "請輸入搜索ID",
  "扣除权益币数量": "扣除權益幣數量",
  "扣除注册币数量": "扣除注册幣數量",
  "兑换子币数量": "兌換子幣數量",
  "请输入兑换数量": "請輸入兌換數量",
  "请输入交易密码": "請輸入交易密碼",
  "兑换注册币": "兌換注册幣",
  "兑换子币": "兌換子幣",
  "扣除数量": "扣除數量",
  "请输入转赠数量": "請輸入轉贈數量",
  "转增记录": "轉增記錄",
  "支出": "支出",
  "子币余额": "子幣餘額",
  "燃烧币余额": "燃燒幣餘額",
  "中文（繁体）": "中文（繁體）",
  "开通子账号": "開通子帳號",
  "子账号": "子帳號",
  "请选择等级": "請選擇等級",
  "查看图片": "查看圖片",
  "子账号股权数": "子賬號股权數",
  "USDT价格": "USDT價格",
  "申诉": "申訴",
  "申诉理由": "申訴理由",
  "金额": "金额",
  "编号": "编号",
  "尾页": "尾页",
  "首页": "首页",
}
