<template>
  <div class="gameBox">
    <nav-bar :title="$t('游戏')" bg-color="transparent" title-color="#c6ba88"/>
    <div class="txt">
      <img alt="" class="img" src="@/assets/img/game1.png">
      {{ $t('游戏板块建设中') }}~
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  created() {
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.gameBox {
  width: 100%;
  min-height: 100vh;
  background-color: #2c2e2f;
  padding: 40px 0 15px;

  .txt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #c6ba88;

    .img {
      width: 132px;
      height: 132px;
    }
  }
}
</style>
