<template>
  <div class="transferListBox">
    <nav-bar :title="$t('转增记录')" bg-color="transparent" left-arrow left-color="#fff" title-color="#fff" />
    <div class="listBox">
      <page-list :finished-text="$t('没有更多了')" :offset="0" api="/transfer/list" :sendData="{ type }"
        @changelist="(data) => { list = [...list, ...data] }">
        <div v-for="(item, index) in list" :key="index" class="list">
          <p class="top fxd">
            <span class="left">{{ $t('转赠') }}-{{ item.to_member.account }}</span>
            <span class="right">{{ item.amount }}</span>
          </p>
          <p class="time fxd">
            <span class="left">{{ item.created_at }}</span>
            <span class="left">{{ $t('支出') }}：{{ (item.amount * 1) + (item.fee * 1) }}</span>
          </p>
        </div>
      </page-list>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        list: [],
        type: this.urlQuery().type,//1:权益币兑换注册币 2 注册币兑换子币
      }
    },
    computed: {},
    watch: {},
    methods: {},
    created() {
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .transferListBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px;
    .listBox {
      .list {
        padding: 10px;
        border-radius: 10px;
        background-color: #343638;
        display: flex;
        flex-direction: column;
        gap: 5px 0;
        margin-bottom: 10px;
        .fxd {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .top {
          .left {
            color: #ffffff;
            font-weight: bold;
          }
          .right {
            color: #dbd3b5;
          }
        }
        .nowPrice {
          .left {
            font-size: 10px;
            color: #999999;
            .pr {
              color: #ffffff;
            }
          }
        }
        .time {
          font-size: 10px;
          color: #6b6d6f;
          .right {
            display: flex;
            align-items: center;
            gap: 0 5px;
            &.t1 {
              color: #9b907f;
            }
            &.t2 {
              color: #ffffff;
            }
            &.t3 {
              color: #77313b;
            }
          }
        }
      }
    }
    ::v-deep .van-overlay {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .auditBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #2c2e2f;
        border-radius: 10px;
        width: 80%;
        padding: 20px;
        .title {
          color: #ffffff;
          font-size: 18px;
          font-weight: bold;
        }
        .content {
          margin-top: 30px;
          color: #999999;
        }
        .btn {
          margin-top: 30px;
          color: #ffffff;
          padding: 10px 50px;
          border-radius: 8px;
          background-color: #ba9d65;
        }
      }
      //.btn {
      //  position: absolute;
      //  color: #ffffff;
      //  bottom: -50px;
      //  left: 50%;
      //  transform: translateX(-50%);
      //}
    }
  }
</style>
