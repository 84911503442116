<template>
  <div class="networkBox">
    <nav-bar :title="$t('安置网络')" bg-color="transparent" left-arrow left-color="#fff" title-color="#fff" />
    <van-search v-model="show_id" show-action :placeholder="$t('请输入搜索ID')" @search="onSearch">
      <template #action>
        <div @click="onSearch">{{ $t('搜索') }}</div>
      </template>
    </van-search>
    <div class="tableBox">
      <table border="1" class="table">
        <tr>
          <th class="title" colspan="3">{{ $t('会员编号') }}：{{ userMember?.show_id }}</th>
        </tr>
        <!--        <tr>-->
        <!--          <th class="name" colspan="3">{{ $t('会员邮箱') }}：{{ userMember?.account }}</th>-->
        <!--        </tr>-->
        <tr>
          <td class="leftText">{{ $t('会员等级') }}</td>
          <td class="content">{{ userMember.level_id }}</td>
        </tr>
        <tr>
          <td class="leftText">{{ $t('累计贡献业绩') }}</td>
          <td class="content">{{ userMember?.amount }}</td>
        </tr>
        <tr>
          <th class="time" colspan="3">{{ userMember?.created_at }}</th>
        </tr>
      </table>
    </div>
    <div class="sonBox">
      <div class="tableBox">
        <table v-if="leftMember" border="1" class="table" @click="getNodeInfo(leftMember.show_id)">
          <tr>
            <th class="title" colspan="3">{{ $t('会员编号') }}：{{ leftMember?.show_id }}</th>
          </tr>
          <!--          <tr>-->
          <!--            <th class="name" colspan="3">{{ $t('会员邮箱') }}：{{ leftMember?.account }}</th>-->
          <!--          </tr>-->
          <tr>
            <td class="leftText">{{ $t('会员等级') }}</td>
            <td class="content">{{ leftMember?.level_id }}</td>
          </tr>
          <tr>
            <td class="leftText">{{ $t('累计贡献业绩') }}</td>
            <td class="content">{{ leftMember?.amount }}</td>
          </tr>
          <tr>
            <th class="time" colspan="3">{{ leftMember?.created_at }}</th>
          </tr>
        </table>
      </div>
      <div class="tableBox">
        <table v-if="rightMember" border="1" class="table" @click="getNodeInfo(rightMember.show_id)">
          <tr>
            <th class="title" colspan="3">{{ $t('会员编号') }}：{{ rightMember?.show_id }}</th>
          </tr>
          <!--          <tr>-->
          <!--            <th class="name" colspan="3">{{ $t('会员邮箱') }}：{{ rightMember?.account }}</th>-->
          <!--          </tr>-->
          <tr>
            <td class="leftText">{{ $t('会员等级') }}</td>
            <td class="content">{{ rightMember?.level_id }}</td>
          </tr>
          <tr>
            <td class="leftText">{{ $t('累计贡献业绩') }}</td>
            <td class="content">{{ rightMember?.amount }}</td>
          </tr>
          <tr>
            <th class="time" colspan="3">{{ rightMember?.created_at }}</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        show_id: '',
        userMember: {},
        leftMember: null,
        rightMember: null,
      }
    },
    computed: {},
    watch: {},
    methods: {
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          loading: true,
          success: (res) => {
            // this.member_id = res.data.show_id
            this.getNodeInfo()
          },
          tip: () => {
          }
        })
      },
      onSearch() {
        this.getNodeInfo()
      },
      getNodeInfo(id) {
        const show_id = id || this.show_id
        this.$get({
          url: `/member/nodeInfo`,
          loading: true,
          data: { show_id },
          success: (res) => {
            this.userMember = res.data
            this.leftMember = res.data?.left_member
            this.rightMember = res.data?.right_member
          },
          tip: () => {
          }
        })
      },
    },
    created() {
      this.getMemberInfo()
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .networkBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px;
    :deep(.van-search) {
      width: 100%;
      padding: 0;
      background-color: #2c2e2f;
      .van-search__content {
        border-radius: 5px;
      }
      .van-search__action {
        color: #ffffff;
      }
      .van-search__action:active {
        background: #898e91;
        border-radius: 5px;
      }
    }
    .table {
      width: 100%;
      border-collapse: collapse;
      border: 2px solid #47494b;
      border-radius: 8px;
      margin-top: 30px;
      overflow: hidden;
      .title {
        background-color: #54585b;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        padding: 8px 0;
      }
      .name {
        padding: 5px 0;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        background-color: #343638;
      }
      .leftText {
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        padding: 5px 0;
        background-color: #343638;
        //width: 170px;
      }
      .content {
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        padding: 5px 0;
        background-color: #343638;
      }
      .time {
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        padding: 5px 0;
        background-color: #343638;
      }
    }
    .sonBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tableBox {
        width: 49%;
      }
    }
  }
</style>
