<template>
  <div class="container">
    <nav-bar :title="['', $t('用户协议'), $t('隐私政策')][type]" left-arrow />
    <div v-if='list' class="htmlcontentbox content" v-html="list"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        type: 0,
        list: '',
      }
    },
    created() {
      this.type = this.$route.query.type
      this.getinfo()
    },
    methods: {
      getinfo() {
        this.$get({
          loading: true,
          url: "/agreement",
          data: {
            type: this.type
          },
          success: (res) => {
            this.list = res.data.content
          },
          tip: () => {
          },
        })
      },
    },
  }
</script>
<style lang="less" scoped>
  .container {
    width: 100vw;
    min-height: 100vh;
    height: auto;
    padding: 0 15px;
    .content {
      width: 100%;
      background: rgba(0, 87, 255, 0.5);
      border-radius: 10px;
      padding: 10px 14px;
      color: #fff;
      font-size: 14px;
    }
  }
</style>
