<template>
  <div class="alertNameBox">
    <nav-bar :title="$t('修改昵称')" bg-color="transparent" left-arrow left-color="#fff" title-color="#fff" />
    <div class="alertBox">
      <van-field v-model="value" :border="false" :label="$t('昵称')" :placeholder="$t('请输入昵称')" input-align="right" />
      <p class="alertBtn" @click="save()">{{ $t('保存') }}</p>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        value: "",
      }
    },
    computed: {},
    watch: {},
    methods: {
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          loading: true,
          success: (res) => {
            this.value = res.data.nickname
          },
          tip: () => {
          }
        })
      },
      save() {
        if (!this.value) return this.$toast(this.$t('请输入昵称'))
        this.$post({
          url: `/member/editNickName`,
          loading: true,
          data: {
            nickname: this.value
          },
          success: (res) => {
            this.$toast(res.message)
          },
          tip: () => {
          }
        })
      },
    },
    created() {
      this.getMemberInfo()
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .alertNameBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px;
    .alertBox {
      ::v-deep .van-field {
        background-color: #343638;
        border-radius: 8px;
        .van-field__label {
          color: #ffffff;
        }
        .van-field__value {
          .van-field__control {
            color: #ffffff;
          }
        }
      }
      .alertBtn {
        background-color: #bb9d67;
        color: #ffffff;
        margin-top: 150px;
        padding: 10px 0;
        text-align: center;
        border-radius: 8px;
      }
    }
  }
</style>
