<template>
  <div class="noticeDetailBox">
    <nav-bar bg-color="#2c2e2f" left-arrow left-color="#fff" :title="$t('公告详情')" title-color="#fff" />
    <div class="titleTxt">{{ title }}</div>
    <div class="detail" v-html="detail" />
  </div>
</template>
<script>
  export default {
    data() {
      return {
        noticeDetailId: "",
        detail: "",
        title: "",
      }
    },
    computed: {},
    watch: {},
    methods: {
      getInfo() {
        this.$get({
          url: `/notice/info?id=${this.noticeDetailId}`,
          loading: true,
          success: (res) => {
            this.detail = res.data.content
            this.title = res.data.title
          },
          tip: () => {
          }
        })
      },
    },
    created() {
      this.noticeDetailId = this.$route.query.id
      this.getInfo()
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .noticeDetailBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px 30px;
    .titleTxt {
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
    }
    .detail {
      color: #ffffff;
      width: 100%;
    }
  }
</style>
