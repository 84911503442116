<template>
  <div class="tradeListBox">
    <nav-bar :title="$t('交易记录')" bg-color="#343638" left-arrow left-color="#fff" title-color="#fff" />
    <div class="listTabBox">
      <div class="topTab">
        <p class="tab" @click="altCheckTab(0)">
          <span :class="{ cl: checkTab === 0 }" class="name">{{ $t('买单') }}</span>
          <span :class="{ check: checkTab === 0 }" class="line" />
        </p>
        <p class="tab" @click="altCheckTab(1)">
          <span :class="{ cl: checkTab === 1 }" class="name">{{ $t('卖单') }}</span>
          <span :class="{ check: checkTab === 1 }" class="line" />
        </p>
      </div>
      <div class="listBox">
        <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad">
          <div v-for="(item, index) in list" :key="index" class="list">
            <p class="top">
              <span class="left">{{ item.sn }}</span>
              <span class="type">{{ $t(status[item.status]) }}</span>
            </p>
            <span class="line" />
            <p class="content">
              <span class="txt left">
                <span class="price">{{ item.price }}</span>
                <span class="tx">{{ $t('单价') }}</span>
              </span>
              <span class="txt cont">
                <span class="price">{{ item.amount }}</span>
                <span class="tx">{{ $t('数量') }}</span>
              </span>
              <span class="txt right">
                <span class="price">{{ item.total }}U</span>
                <span class="tx">${{ $t('数量') }}</span>
              </span>
            </p>
            <span class="line" />
            <p class="btm">
              <span class="left">
                <span v-if="![0, 4, 5].includes(item.status) && checkTab == 0" class="address">{{ $t('交易地址') }}：{{
                  item.sellerInfo.usdt_address }}</span>
                <span v-if="checkTab == 1 && ![4, 5].includes(item.status)" class="time">{{ $t('获得') }}$：{{ item.total
                  }}</span>
                <span v-if="checkTab == 1">{{ $t('联系方式') }}：{{ item?.sellerInfo?.phone || '' }}</span>
                <span v-if="![0, 4, 5].includes(item.status)" class="time">{{ $t('交易时间') }}：{{ item.buy_time }}</span>
              </span>
              <span class="right">
                <img v-if="item.status == 2" :src="httpPath + item.pay_img" alt="" class="showImg"
                  @click="openSeeImg(httpPath + item.pay_img)">
                <van-uploader v-if="item.status == 1 && checkTab == 0" :after-read="e => afterRead(e, index)" :max-count="1"
                  preview-size="30px">
                  <img v-if="!item?.showImg" alt="" class="uploadImg" src="@/assets/img/upload.png">
                  <img v-else :src="item?.showImg" alt="" class="uploadImg">
                </van-uploader>
                <span class="btnBox">
                  <span v-if="item.status == 0" class="btn" @click="orderCancel(item.id)">{{ $t('取消') }}</span>
                  <span v-if="item.status == 1 && checkTab == 0" class="btn" @click="orderPay(item.id, index)">{{ $t('上传凭证')
                    }}</span>
                  <span v-if="item.status == 2 && checkTab == 1" class="btn" @click="orderConfirm(item.id)">{{ $t('确定交易')
                    }}</span>
                  <span v-if="item.status == 1 && checkTab == 0" class="btn" @click="complaint(item.id)">{{ $t('申诉') }}</span>
                  <span v-if="item.status == 2 && checkTab == 1" class="btn" @click="complaint(item.id)">{{ $t('申诉') }}</span>
                </span>
              </span>
            </p>
          </div>
        </van-list>
      </div>
    </div>
    <van-popup v-model="match">
      <div class="matchBox">
        <p class="title">{{ $t('申诉') }}</p>
        <van-field v-model="reason" :border="false" :label="$t('申诉理由')" :placeholder="$t('请输入申诉理由')" type="textarea" />
        <p class="btnBox">
          <span class="btnS canBtn" @click="closePop()">{{ $t('取消') }}</span>
          <span class="btnS matchBtn" @click="postMatch()">{{ $t('提交') }}</span>
        </p>
      </div>
    </van-popup>
    <van-image-preview v-model="showImgType" :images="images" />
  </div>
</template>
<script>
  export default {
    data() {
      return {
        status: ["待匹配", "待付款", "待确认", "已完成", "已投诉", "已取消"],
        checkTab: 0,
        page: 1,
        loading: false,
        finished: false,
        list: [],
        complaint_id: "",
        match: false,
        reason: "",
        showImg: [],
        showImgType: false,
        images: [],
      }
    },
    computed: {},
    watch: {},
    methods: {
      onLoad() {
        let page = this.page++
        this.$post({
          url: `/trade/orderList`,
          loading: true,
          data: {
            type: [1, 2][this.checkTab],
            page
          },
          success: (res) => {
            if (res.data.data.length === 0) {
              this.finished = true
            } else {
              let list = res.data.data
              list.map((item) => {
                item.showImg = ""
                item.file = ""
              })
              this.list = [...this.list, ...list]
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          }
        })
      },
      // 切换 tab 栏
      altCheckTab(itm) {
        this.checkTab = itm
        this.page = 1
        this.list = []
        this.onLoad()
      },
      // 取消买卖单
      orderCancel(id) {
        this.$post({
          url: `/trade/orderCancel`,
          loading: true,
          data: {
            order_id: id
          },
          success: (res) => {
            this.$toast({
              message: res.message,
              onClose: () => {
                this.page = 1
                this.list = []
                this.onLoad()
              }
            })
          },
          tip: () => {
          }
        })
      },
      // 申诉按钮
      complaint(id) {
        this.complaint_id = id
        this.match = true
      },
      // 取消申诉
      closePop() {
        this.complaint_id = ""
        this.match = false
        this.reason = ""
      },
      // 确认申诉
      postMatch() {
        if (!this.reason) return this.$toast(this.$t('请输入申诉理由'))
        this.$post({
          url: `/trade/complaint`,
          loading: true,
          data: {
            order_id: this.complaint_id,
            reason: this.reason
          },
          success: (res) => {
            this.complaint_id = ""
            this.match = false
            this.reason = ""
            this.$toast({
              message: res.message,
              onClose: () => {
                this.page = 1
                this.list = []
                this.onLoad()
              }
            })
          },
          tip: () => {
          }
        })
      },
      // 上传支付凭证图片
      afterRead(e, index) {
        this.list[index].showImg = e.content
        let uploadFile = new FormData()
        uploadFile.append("file", e.file)
        this.$post({
          url: `/upload/local/image`,
          loading: true,
          upload: true,
          data: uploadFile,
          success: (res) => {
            this.$toast({
              message: res.message,
              onClose: () => {
                this.list[index].file = res.data
              }
            })

          },
          tip: () => {
          }
        })
      },
      // 上传凭证
      orderPay(sn, index) {
        this.$post({
          url: `/trade/orderPay`,
          loading: true,
          data: {
            order_id: sn,
            pay_img: this.list[index].file
          },
          success: (res) => {
            this.$toast({
              message: res.message,
              onClose: () => {
                this.page = 1
                this.list = []
                this.onLoad()
              }
            })
          },
          tip: () => {
          }
        })
      },
      // 查看凭证图片
      openSeeImg(img) {
        this.images = [`${img}`]
        this.showImgType = true
      },
      // 订单确认
      orderConfirm(id) {
        this.$post({
          url: `/trade/orderConfirm`,
          loading: true,
          data: {
            order_id: id
          },
          success: (res) => {
            this.$toast({
              message: res.message,
              onClose: () => {
                this.page = 1
                this.list = []
                this.onLoad()
              }
            })
          },
          tip: () => {
          }
        })
      },
    },
    created() {
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .tradeListBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    position: relative;
    .listTabBox {
      .topTab {
        padding: 30px 40px 0;
        background-color: #343638;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .tab {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 10px 0;
          .name {
            color: #ffffff;
            &.cl {
              color: #cbac7b;
            }
          }
          .line {
            width: 20px;
            height: 2px;
            background-color: transparent;
            &.check {
              background-color: #d9d1ba;
            }
          }
        }
      }
      .listBox {
        padding: 20px 10px;
        background-color: #2c2e2f;
        .list {
          background-color: #343638;
          border-radius: 8px;
          margin-bottom: 10px;
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            .left {
              color: #999999;
            }
            .type {
              color: #828272;
            }
          }
          .line {
            width: 100%;
            height: 1px;
            background-color: #999999;
            display: block;
          }
          .content {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .txt {
              color: #ffffff;
              display: flex;
              flex-direction: column;
              .price {
                font-weight: bold;
                font-size: 16px;
              }
              .tx {
                font-size: 12px;
              }
            }
            .left {
              align-items: flex-start;
            }
            .cont {
              align-items: center;
            }
            .right {
              align-items: flex-end;
            }
          }
          .btm {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            .left {
              color: #999999;
              display: flex;
              flex-direction: column;
              gap: 5px 0;
              font-size: 10px;
            }
            .right {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 10px 0;
              ::v-deep .van-uploader {
                border-radius: 10px;
                overflow: hidden;
                padding: 0;
                margin: 0;
                .van-uploader__wrapper {
                  .van-uploader__input-wrapper {
                    .uploadImg {
                      width: 45px;
                      height: 45px;
                    }
                  }
                }
              }
              .showImg {
                width: 45px;
                height: 45px;
                border-radius: 10px;
              }
              .btnBox {
                display: flex;
                align-items: center;
                gap: 0 10px;
                color: #ffffff;
                .btn {
                  background-color: #bd9f68;
                  border-radius: 8px;
                  padding: 5px 10px;
                }
              }
            }
          }
        }
      }
    }
    ::v-deep .van-popup {
      background-color: #2c2e2f;
      border-radius: 8px;
      width: 80%;
      .matchBox {
        padding: 15px;
        .title {
          color: #ffffff;
          text-align: center;
          margin-bottom: 30px;
          font-weight: bold;
          font-size: 18px;
        }
        .nowPrice {
          color: #d1c6b6;
          margin-bottom: 20px;
          font-size: 16px;
        }
        .van-field {
          background-color: #343638;
          border-radius: 8px;
          .van-field__label {
            color: #ffffff;
          }
          .van-field__value {
            .van-field__control {
              color: #ffffff;
            }
          }
        }
        .btnBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          .btnS {
            width: 47%;
            padding: 10px 0;
            text-align: center;
            color: #ffffff;
            border-radius: 6px;
          }
          .canBtn {
            background-color: #848484;
          }
          .matchBtn {
            background-color: #bfa16d;
          }
        }
      }
    }
  }
</style>
