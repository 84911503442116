<template>
  <div class="levelUpBox">
    <nav-bar :title="$t('权益等级升级')" bg-color="transparent" left-arrow left-color="#fff" title-color="#fff"/>
    <div class="levelBox">
      <div class="level">
        <p class="top">
          <span class="lv">{{ next.level_name || "" }}VIP</span>
          <span class="name">{{ info.account || "" }}</span>
          <img alt="" class="bgImg" src="@/assets/img/mine14.png">
        </p>
        <p class="lvTxtBox">
          <span class="lvTxt">{{ $t('可升级') }} {{ next.next_level_name || "" }} VIP，{{ $t('需') }} {{ next.next_amount || "0.00" }} {{ $t('注册币') }}</span>
          <span class="lvPrice">{{ $t('注册币余额') }}：{{ info.coin || "0.00" }}</span>
        </p>
      </div>
      <div class="btnBox">
        <p class="btn" @click="upgradeDo()">{{ $t('立即升级') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
      next: {},
    }
  },
  computed: {},
  watch: {},
  methods: {
    getMemberInfo() {
      this.$get({
        url: `/member/getMemberInfo`,
        loading: true,
        success: (res) => {
          this.info = res.data
          this.getNextLevel()
        },
        tip: () => {
        }
      })
    },
    getNextLevel() {
      this.$get({
        url: `/member/getNextLevel`,
        loading: true,
        success: (res) => {
          this.next = res.data
        },
        tip: () => {
        }
      })
    },
    upgradeDo() {
      this.$get({
        url: `/upgradeLog/upgradeDo`,
        loading: true,
        success: (res) => {
          this.$toast(res.message)
        },
        tip: () => {
        }
      })
    },
  },
  created() {
    this.getMemberInfo()
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.levelUpBox {
  width: 100%;
  min-height: 100vh;
  background-color: #2c2e2f;
  padding: 15px;

  .levelBox {
    .level {
      padding: 20px;
      border-radius: 10px;
      background: linear-gradient(180deg, #534A3B 0%, #2F2820 100%);

      .top {
        position: relative;
        display: flex;
        flex-direction: column;

        .lv {
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
        }

        .name {
          color: #91897e;
          font-size: 12px;
        }

        .bgImg {
          position: absolute;
          width: 76px;
          height: 76px;
          right: -5px;
          top: -5px;
        }
      }

      .lvTxtBox {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        .lvTxt {
          color: #AEAAA1;
        }

        .lvPrice {
          font-size: 10px;
          color: #E5D3B2;
        }
      }
    }

    .btnBox {
      padding: 0 20px;

      .btn {
        padding: 10px 0;
        text-align: center;
        color: #ffffff;
        background-color: #b99c65;
        border-radius: 6px;
        margin-top: 80px;
      }
    }
  }
}
</style>
