<template>
  <div class="alertPassBox">
    <nav-bar :title="$t('修改登录密码')" bg-color="transparent" left-arrow left-color="#fff" title-color="#fff" />
    <div class="inputBox">
      <van-field v-model="value" :border="false" :label="$t('邮箱')" :placeholder="$t('请输入邮箱')" readonly />
      <van-field v-model="code" :border="false" :label="$t('验证码')" :placeholder="$t('请输入验证码')" class="filedBorder">
        <template #button>
          <span v-show="!serType" class="codeBtn" @click="sendCode()">{{ $t('获取验证码') }}</span>
          <span v-show="serType" class="codeBtn">{{ serS }}S{{ $t('后获取') }}</span>
        </template>
      </van-field>
      <van-field v-model="re_password" :border="false" :label="$t('新密码')" :placeholder="$t('请输入新密码')"
        class="filedBorder" type="password" />
      <van-field v-model="password" :border="false" :label="$t('重复密码')" :placeholder="$t('请输入重复密码')" type="password" />
    </div>
    <div class="btnBox">
      <p class="btn" @click="editPassword()">{{ $t('保存') }}</p>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        value: "",
        code: "",
        re_password: "",
        password: "",
        setTime: null,
        serS: 60,
        serType: false
      }
    },
    computed: {},
    watch: {},
    methods: {
      // 获取会员信息
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          loading: true,
          success: (res) => {
            this.value = res.data.account
          },
          tip: () => {
          }
        })
      },
      // 获取验证码
      sendCode() {
        this.$post({
          url: `/send/code`,
          loading: true,
          data: {
            to: this.value,
            scene: "modify_password"
          },
          success: () => {
            clearInterval(this.setTime)
            this.setTime = null
            this.serType = true
            this.setTime = setInterval(() => {
              if (this.serS > 0) {
                this.serS--
              } else {
                clearInterval(this.setTime)
                this.setTime = null
                this.serS = 60
                this.serType = false
              }
            }, 1000)
          },
          tip: () => {
          }
        })
      },
      // 修改登录密码
      editPassword() {
        if (!this.code) return this.$toast(this.$t('请输入验证码'))
        if (!this.password) return this.$toast(this.$t('请输入新密码'))
        if (!this.re_password) return this.$toast(this.$t('请重复输入密码'))
        if (this.password != this.re_password) return this.$toast(this.$t('两输入的交易密码不一致'))
        this.$post({
          url: `/member/editPassword`,
          loading: true,
          data: {
            code: this.code,
            password: this.password,
            re_password: this.password
          },
          success: (res) => {
            this.$toast(res.message)
          },
          tip: () => {
          }
        })
      },
    },
    created() {
      this.getMemberInfo()
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .alertPassBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px;
    .inputBox {
      border-radius: 8px;
      padding: 0 15px 0;
      background-color: #343638;
      .filedBorder {
        border-top: 1px solid #3e4042;
        border-bottom: 1px solid #3e4042;
      }
      ::v-deep .van-field {
        background-color: #343638;
        padding: 10px 0;
        .van-field__label {
          color: #ffffff;
        }
        .van-field__value {
          .van-field__control {
            color: #ffffff;
          }
          .van-field__button {
            padding: 0;
            .codeBtn {
              border: 1px solid #E5D3B2;
              color: #E5D3B2;
              border-radius: 100px;
              padding: 5px 10px;
            }
          }
        }
      }
    }
    .btnBox {
      margin-top: 100px;
      padding: 0 20px;
      .btn {
        background-color: #bb9d67;
        color: #ffffff;
        padding: 10px 0;
        text-align: center;
        border-radius: 8px;
      }
    }
  }
</style>
