<template>
  <div>
    <van-button :color="color" :disabled="disabled" :loading="loading" :plain="plain" :round="round" :size="size" :square="square" :type="type" class="anBtn" hairline native-type="button"
                @click="sendcode">
      {{ disabled ? `${time}S` : '发送验证码' }}
    </van-button>
  </div>
</template>
<script>
// https://vant-contrib.gitee.io/vant/v2/#/zh-CN/button#api
export default {
  props: {
    // 接口
    api: {
      type: String,
      default: "/app/sms/sendSms",
    },
    // 发送后端的手机号 key:value
    sendTell: {
      type: Object,
      default: () => ({phone: ""}),
    },
    // 发送后端的场景值 key:value
    sendScene: {
      type: Object,
      default: () => ({scene: ""}),
    },
    //不发送 token  '@/src/request/http.js'
    noToken: {type: Boolean, default: true},
    type: {type: String, default: "primary"},
    size: {type: String, default: "mini"},
    color: String,
    plain: Boolean,
    square: Boolean,
    round: Boolean,
  },
  data() {
    return {
      time: 60,
      disabled: false,
      loading: false,
    }
  },
  methods: {
    sendcode() {
      if (this.disabled) return
      let key = Object.keys(this.sendTell)[0]
      let tell = this.sendTell[key]
      if (!tell) return this.$toast("请输入手机号")
      if (!this.$rg.isPhone(tell)) return this.$toast("请输入正确格式的手机号")
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })
      this.loading = true
      this.$post({
        url: this.api,
        data: {...this.sendTell, ...this.sendScene},
        noToken: this.noToken,
        success: () => {
          this.$toast("发送成功")
          this.loading = false
          this.disabled = true
          this.time = Number(this.time)
          let timer = setInterval(() => {
            this.time--
            if (this.time == 0) {
              clearInterval(timer)
              this.disabled = false
            }
          }, 1000)
        },
        tip: () => {
          this.loading = false
        },
      })
    },
  },
}
</script>
