<template>
  <div class="walletAddressBox">
    <nav-bar :title="$t('客服')" bg-color="#2c2e2f" left-arrow left-color="#fff" title-color="#fff" :right-text="$t('记录')"
      right-color="#fff" @click-right="router('feedbackList')" />
    <van-field v-model="value" type="textarea" autosize :border="false" :placeholder="$t('请输入留言内容')" />
    <div style="width: 100%;height: 20px;"></div>
    <van-uploader v-model="fileList" :after-read="afterRead" :max-count="6" />

    <div class="btnBox">
      <p class="btn" @click="submit()">{{ $t('提交') }}</p>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        value: "",
        fileList: [],
      }
    },
    computed: {},
    watch: {},
    methods: {
      afterRead(e) {
        e.status = 'uploading';
        let uploadFile = new FormData()
        uploadFile.append("file", e.file)
        this.$post({
          url: `/upload/local/image`,
          loading: true,
          upload: true,
          data: uploadFile,
          success: (res) => {
            e.status = 'success';
            e.url = `${window.origin}${res.data}`
            e.sendurl = res.data
          },
          tip: () => {
            e.status = 'failed'
          }
        })
      },
      submit() {
        if (!this.value) return this.$toast(this.$t('请输入留言内容'))
        let image = []
        image = this.fileList.map(item => item.sendurl)
        this.$post({
          url: `/feedback/addFeedback`,
          loading: true,
          data: {
            content: this.value,
            images: image.join(';')
          },
          success: (res) => {
            this.value = ''
            this.fileList = []
            this.$toast(this.$t('提交成功'))
          },
          tip: () => {

          }
        })
      },
    },
    created() { },
    mounted() { },
  }
</script>

<style lang="less" scoped>
  .walletAddressBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 20px 15px;
    ::v-deep .van-field {
      background-color: #343638;
      border-radius: 8px;
      .van-field__label {
        color: #ffffff;
      }
      .van-field__value {
        .van-field__control {
          color: #ffffff;
        }
      }
    }
    /deep/.van-uploader {
      .van-uploader__upload {
        border-radius: 8px;
        background-color: #343638;
      }
    }
    .btnBox {
      margin-top: 100px;
      padding: 0 15px;
      .btn {
        background-color: #bd9f6b;
        border-radius: 10px;
        padding: 10px 0;
        text-align: center;
        color: #ffffff;
      }
    }
  }
</style>
