<template>
  <div class="walletAddressBox">
    <nav-bar :title="$t('钱包地址')" bg-color="#2c2e2f" left-arrow left-color="#fff" title-color="#fff" />
    <van-field v-model="value" type="textarea" autosize :border="false" :label="$t('币安链地址')"
      :placeholder="$t('请输入币安链钱包地址')" input-align="right" />
    <div class="btnBox">
      <p class="btn" @click="editUsdtAddress()">{{ $t('保存') }}</p>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        value: "",
      }
    },
    computed: {},
    watch: {},
    methods: {
      // 获取钱包地址
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          loading: true,
          success: (res) => {
            this.value = res.data.usdt_address
          },
          tip: () => {
          }
        })
      },
      // 修改钱包地址
      editUsdtAddress() {
        this.$post({
          url: `/member/editUsdtAddress`,
          loading: true,
          data: {
            usdt_address: this.value
          },
          success: (res) => {
            this.$toast(res.message)
          },
          tip: () => {
          }
        })
      },
    },
    created() {
      this.getMemberInfo()
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .walletAddressBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 20px 15px;
    ::v-deep .van-field {
      background-color: #343638;
      border-radius: 8px;
      .van-field__label {
        color: #ffffff;
      }
      .van-field__value {
        .van-field__control {
          color: #ffffff;
        }
      }
    }
    .btnBox {
      margin-top: 100px;
      padding: 0 15px;
      .btn {
        background-color: #bd9f6b;
        border-radius: 10px;
        padding: 10px 0;
        text-align: center;
        color: #ffffff;
      }
    }
  }
</style>
