<template>
  <div class="exChangeBox">
    <nav-bar :right-text="$t('兑换记录')" :title="$t('兑换')" bg-color="transparent" left-arrow left-color="#fff"
      right-color="#fff" title-color="#fff" @click-right="router(`/exList?type=${type}`)" />
    <div class="box">
      <div class="topBalance" v-if="type == 1">
        <p class="title">{{ $t('权益币余额') }}</p>
        <p class="price">{{ info.equity || 0 }}</p>
      </div>
      <div class="topBalance" v-if="type == 2">
        <p class="title">{{ $t('注册币余额') }}</p>
        <p class="price">{{ info.coin || 0 }}</p>
      </div>

      <div class="convertBox">
        <p class="title" v-if="type == 1">{{ $t('兑换注册币数量') }}</p>
        <p class="title" v-if="type == 2">{{ $t('兑换子币数量') }}</p>
        <van-field v-model="value" :border="false" :placeholder="$t('请输入兑换数量')" />
        <p class="costBox">
          <span>{{ $t('手续费') }}：{{ exchange_fee || 0 }}%</span>
          <span v-if="type == 1">{{ $t('扣除权益币数量') }}：{{ exC }}</span>
          <span v-if="type == 2">{{ $t('扣除注册币数量') }}：{{ exC }}</span>
        </p>
        <van-field class="passbox" v-model="password" clearable type="password" :border="false"
          :placeholder="$t('请输入交易密码')" maxlength="6" />
      </div>


      <div class="avow" v-html="exchange_info" />
      <div class="btnBox">
        <p class="btn" @click="exchangeDo()">{{ $t('兑换') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        value: "",

        info: {},
        exchange_fee: "",
        exchange_info: "",
        exchange_open: "",
        equity_price: "",
        password: '',
        type: this.urlQuery().type,//1:权益币兑换注册币 2 注册币兑换子币
      }
    },
    computed: {
      exC: {
        get() {
          return (Number(this.value) + Number(this.value * (this.exchange_fee / 100))) / this.equity_price || 0
        },
        set() {
        }
      }
    },
    watch: {},
    methods: {
      // 获取兑换配置
      getConfig() {
        this.$get({
          url: `/exchange/getConfig`,
          loading: true,
          success: (res) => {
            this.exchange_fee = res.data.exchange_fee
            this.exchange_info = res.data.exchange_info
            this.exchange_open = res.data.exchange_open
            this.getHomeData()
          },
          tip: () => {
          }
        })
      },
      // 获取权益币实时价格
      getHomeData() {
        this.$get({
          url: `trade/getTradeConfigs`,
          loading: true,
          success: (res) => {
            this.equity_price = res.data.equity_price
            this.getMemberInfo()
          },
          tip: () => {
          }
        })
      },
      // 获取信息
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          loading: true,
          success: (res) => {
            this.info = res.data
          },
          tip: () => {
          }
        })
      },
      // 兑换注册币
      exchangeDo() {
        if (this.exchange_open == 0) return this.$toast(this.$t('兑换暂未开放'))
        if (!this.value) return this.$toast(this.$t('请输入兑换数量'))
        if (!this.password) return this.$toast(this.$t('请输入交易密码'))
        this.$post({
          url: `/exchange/do`,
          loading: true,
          data: {
            amount: this.value,
            password: this.password,
            type: this.type,
          },
          success: (res) => {
            this.$toast({
              message: res.message,
              onClose: () => {
                this.value = ""
                this.getMemberInfo()
              }
            },)
          },
          tip: () => {
          }
        })
      },
    },
    created() {
      // this.price = this.$route.query.price
      this.getConfig()
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .exChangeBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px;
    .box {
      .topBalance {
        background-color: #343638;
        border-radius: 8px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        gap: 10px 0;
        .title {
          font-weight: bold;
          font-size: 12px;
        }
        .price {
          font-weight: bold;
          font-size: 20px;
        }
      }
      .convertBox {
        padding: 15px;
        background-color: #343638;
        border-radius: 8px;
        margin-top: 15px;
        color: #ffffff;
        .title {}
        ::v-deep .van-field {
          padding: 0 5px;
          background-color: #343638;
          border-bottom: 1px solid #999999;
          margin-top: 30px;
          .van-field__label {
            color: #ffffff;
          }
          .van-field__value {
            .van-field__control {
              color: #ffffff;
            }
          }
        }
        .passbox {
          margin-top: 15px;
          padding: 5px 0;
          border-bottom: none;
        }
        .costBox {
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .avow {
        margin-top: 10px;
        color: #ffffff;
      }
      .btnBox {
        padding: 0 15px;
        .btn {
          color: #ffffff;
          background-color: #ba9c68;
          border-radius: 8px;
          padding: 10px 0;
          text-align: center;
          margin-top: 100px;
        }
      }
    }
  }
</style>
