<template>
  <div class="marketBox">
    <nav-bar :right-text="$t('交易记录')" :title="$t('交易大厅')" bg-color="#dfcfb5" @click-right="router('/tradeList')" />
    <div class="allPrice">
      <div>
        <p class="topTxt">{{ $t('权益币价格') }}</p>
        <p class="topNum">{{ tradeConfigs.equity_trade_price || "-" }}</p>
      </div>
      <div>
        <p class="topTxt">{{ $t('USDT价格') }}</p>
        <p class="topNum">{{ tradeConfigs.usdt_price || "-" }}</p>
      </div>
    </div>
    <!-- <div class="tabBox">
      <div v-for="(item, index) in tabs" :key="index" class="tabs" @click="cutTab(item.id)">
        <span :class="{ checkTxt: shouTab === item.id }" class="txt">{{ $t(item.name) }}</span>
        <span :class="{ checkLine: shouTab === item.id }" class="line" />
      </div>
    </div> -->
    <div class="listBox">
      <p v-if="shouTab === 0" class="btn" @click="release = true">+ &nbsp;{{ $t('发布买单') }}</p>
      <p v-if="shouTab === 1" class="btn" @click="sellRelease = true">+ &nbsp;{{ $t('发布卖单') }}</p>
      <div class="hallList">
        <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad">
          <div v-for="(item, index) in list" :key="index" class="listBox">
            <p class="topBox">
              <span class="left">
                <span class="address">{{ $t('钱包地址') }}：{{ item.sn }}</span>
                <span class="reNum">{{ $t('发布数量') }}：{{ item.amount }}</span>
                <span class="reTime">{{ $t('发布时间') }}：{{ item.created_at }}</span>
              </span>
              <span class="right">
                <span class="prTxt">{{ $t('价值') }}</span>
                <span class="prBox">
                  <span class="num">{{ item.total }}</span>
                  U
                </span>
              </span>
            </p>
            <van-divider dashed />
            <p class="btnBox">
              <span class="mateBtn" @click="openPopup(item)">{{ $t('我要匹配') }}</span>
            </p>
          </div>
        </van-list>
      </div>
    </div>
    <van-popup v-model="match">
      <div class="matchBox">
        <p class="title">{{ $t('匹配') }}</p>
        <van-field v-model="matchDate.amount" :border="false" :label="$t('数量')" :placeholder="$t('请输入匹配数量')" readonly />
        <van-field v-model="password" clearable type="password" :border="false" :placeholder="$t('请输入交易密码')"
          maxlength="6" />
        <p class="btnBox">
          <span class="btnS canBtn" @click="closePop()">{{ $t('取消') }}</span>
          <span class="btnS matchBtn" @click="postMatch()">{{ $t('提交') }}</span>
        </p>
      </div>
    </van-popup>

    <van-popup v-model="release">
      <div class="matchBox">
        <p class="title">{{ $t('发布买单') }}</p>
        <p class="nowPrice">{{ $t('权益币价格') }}：{{ tradeConfigs.equity_trade_price }}</p>
        <van-field v-model="buyNum" :border="false" :label="$t('购买数量')" :placeholder="$t('请输入购买数量')" />
        <p style="height: 10px;width: 1px;" />
        <van-field v-model="buyPrice" :border="false" :label="$t('价值')" readonly>
          <template #button>$</template>
        </van-field>
        <p style="height: 10px;width: 1px;" />
        <van-field v-model="password" clearable type="password" :border="false" :placeholder="$t('请输入交易密码')"
          maxlength="6" />

        <p class="btnBox">
          <span class="btnS canBtn" @click="closePop()">{{ $t('取消') }}</span>
          <span class="btnS matchBtn" @click="publish()">{{ $t('提交') }}</span>
        </p>
      </div>
    </van-popup>

    <van-popup v-model="sellRelease">
      <div class="matchBox">
        <p class="title">{{ $t('发布卖单') }}</p>
        <p class="nowPrice">{{ $t('权益币余额') }}：{{ info?.equity }}</p>
        <van-field v-model="sellNum" :border="false" :label="$t('出售数量')" :placeholder="$t('请输入出售数量')" />
        <p style="height: 10px;width: 1px;" />
        <van-field v-model="sellPrice" :border="false" :label="$t('支出数量')" readonly />
        <p style="height: 10px;width: 1px;" />
        <van-field v-model="password" clearable type="password" :border="false" :placeholder="$t('请输入交易密码')"
          maxlength="6" />
        <p class="btnBox">
          <span class="btnS canBtn" @click="closePop()">{{ $t('取消') }}</span>
          <span class="btnS matchBtn" @click="publish()">{{ $t('提交') }}</span>
        </p>
      </div>
    </van-popup>
    <van-popup v-model="sellMatch">
      <div class="matchBox">
        <p class="title">{{ $t('匹配') }}</p>
        <van-field v-model="matchDate.amount" :border="false" :label="$t('数量')" :placeholder="$t('请输入卖出数量')" readonly />
        <p style="height: 10px;width: 1px;" />
        <van-field v-model="matchPrice" :border="false" :label="$t('合计支出')" readonly>
          <template #button>{{ $t('手续费') }}：{{ tradeConfigs.trade_fee }}%</template>
        </van-field>
        <van-field v-model="password" clearable type="password" :border="false" :placeholder="$t('请输入交易密码')"
          maxlength="6" />
        <p style="height: 10px;width: 1px;" />
        <!--        <van-field v-model="usdtNum" :border="false" label="USDT"/>-->
        <p class="btnBox">
          <span class="btnS canBtn" @click="closePop()">{{ $t('取消') }}</span>
          <span class="btnS matchBtn" @click="postMatch()">{{ $t('提交') }}</span>
        </p>
      </div>
    </van-popup>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        tabs: [
          // {name: "买单大厅", id: 0},
          { name: "卖单大厅", id: 1 }
        ],
        shouTab: 1,
        match: false, // 买单匹配弹窗
        release: false,// 发布买单弹窗
        sellMatch: false, // 卖单匹配弹窗
        sellRelease: false, // 发布卖单弹窗
        matchNum: "", // 匹配数量
        buyNum: "", // 购买数量
        sellNum: "", // 售卖数量
        usdtNum: 0, // 发布卖单USDT
        tradeConfigs: {}, // 交易配置
        list: [],
        loading: false,
        finished: false,
        page: 1,
        matchDate: {},
        info: {},
        password: '',
      }
    },
    watch: {},
    methods: {
      // 获取交易配置
      getTradeConfigs() {
        this.$get({
          url: `/trade/getTradeConfigs`,
          // loading: true,
          success: (res) => {
            this.tradeConfigs = res.data
            this.getMemberInfo()
          },
          tip: () => {
          }
        })
      },
      // 获取个人信息
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          // loading: true,
          success: (res) => {
            this.info = res.data
          },
          tip: () => {
          }
        })
      },
      // 发布买卖单
      publish() {
        if (![this.buyNum, this.sellNum][this.shouTab]) return this.$toast(`请输入${["购买数量", "出售数量"][this.shouTab]}`)
        if (!this.password) return this.$toast("请输入交易密码")
        this.$post({
          url: `/trade/publish`,
          data: {
            type: [1, 2][this.shouTab],
            amount: [this.buyNum, this.sellNum][this.shouTab],
            password: this.password
          },
          loading: true,
          success: (res) => {
            this.$toast({
              message: res.data,
              onClose: () => {
                this.page = 1
                this.list = []
                this.onLoad()
                this.closePop()
              }
            })
          },
          tip: () => {
            this.password = ''
          }
        })
      },
      // 匹配
      postMatch() {
        if (!this.password) return this.$toast("请输入交易密码")
        this.$post({
          url: `/trade/match`,
          data: {
            id: this.matchDate.id,
            password: this.password,
          },
          loading: true,
          success: (res) => {
            this.password = ''
            this.$toast({
              message: res.message,
              onClose: () => {
                this.page = 1
                this.list = []
                this.onLoad()
                this.closePop()
              }
            })
          },
          tip: () => {
            this.password = ''
          }
        })
      },
      // 关闭买卖单
      closePop() {
        this.release = false
        this.sellRelease = false
        this.match = false
        this.sellMatch = false
        this.buyNum = ""
        this.sellNum = ""
        this.password = ''
        this.matchDate = {}
      },
      // cutTab(id) {
      //   this.shouTab = id
      //   this.page = 1
      //   this.list = []
      //   this.onLoad()
      // },
      // 分页加载数据
      onLoad() {
        let page = this.page++
        this.$get({
          url: `/trade/tradeList`,
          data: {
            page: page,
            type: [1, 2][this.shouTab]
          },
          success: (res) => {
            if (res.data.data.length === 0) {
              this.finished = true
            } else {
              let list = res.data.data
              this.list = [...this.list, ...list]
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
      openPopup(item) {
        this.matchDate = item
        if (this.shouTab === 0) {
          this.match = true
        } else if (this.shouTab === 1) {
          this.sellMatch = true
        }
      },
    },
    created() {
      this.getTradeConfigs()
    },
    mounted() {
    },
    computed: {
      // 计算买单价值
      buyPrice: {
        get() {
          return this.tradeConfigs.equity_trade_price * this.buyNum || 0
        },
        set() {
        },
      },
      // 计算卖单数量
      sellPrice: {
        get() {
          return Number((this.tradeConfigs.trade_fee / 100) * this.sellNum) + Number(this.sellNum) || 0
        },
        set() {
        },
      },
      // 计算匹配卖单
      matchPrice: {
        get() {
          return Number((this.tradeConfigs.trade_fee / 100) * this.matchDate.amount) + Number(this.matchDate.amount) || 0
        },
        set() {
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .marketBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 40px 0 70px;
    ::v-deep .van-nav-bar__placeholder {
      position: fixed;
    }
    .allPrice {
      min-height: 170px;
      background-image: url("../../assets/img/trade1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 70px 20px 40px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      >div {
        min-width: 30%;
        padding: 5px;
        .topTxt {
          font-size: 15px;
          color: #544123;
        }
        .topNum {
          font-size: 28px;
          font-weight: bold;
          color: #544123;
        }
      }
    }
    .tabBox {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: #343638;
      padding-top: 15px;
      .tabs {
        display: flex;
        align-items: center;
        flex-direction: column;
        .txt {
          color: #999999;
          margin-bottom: 10px;
          &.checkTxt {
            color: #ffffff;
          }
        }
        .line {
          width: 20px;
          height: 2px;
          border-radius: 3px;
          background-color: transparent;
          &.checkLine {
            background-color: #ded4b8;
          }
        }
      }
    }
    .listBox {
      padding: 10px;
      margin-bottom: 10px;
      .btn {
        background-color: #d6be92;
        border-radius: 100px;
        color: #ffffff;
        padding: 15px 0;
        text-align: center;
        font-weight: bold;
        font-size: 15px;
      }
      .hallList {
        padding: 10px 0;
        .listBox {
          padding: 10px;
          background-color: #343638;
          border-radius: 8px;
          .topBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
              display: flex;
              flex-direction: column;
              color: #999999;
              font-size: 12px;
              .address {
                font-weight: bold;
                font-size: 16px;
                color: #ffffff;
              }
            }
            .right {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .prTxt {
                color: #999999;
              }
              .prBox {
                color: #d6cebb;
                font-size: 14px;
                .num {
                  font-size: 20px;
                  font-weight: bold;
                }
              }
            }
          }
          .btnBox {
            display: flex;
            justify-content: flex-end;
            .mateBtn {
              background-color: #bda46c;
              border-radius: 100px;
              padding: 5px 10px;
              font-weight: bold;
              color: #ffffff;
            }
          }
        }
      }
    }
    ::v-deep .van-popup {
      background-color: #2c2e2f;
      border-radius: 8px;
      width: 80%;
      .matchBox {
        padding: 15px;
        .title {
          color: #ffffff;
          text-align: center;
          margin-bottom: 30px;
          font-weight: bold;
          font-size: 18px;
        }
        .nowPrice {
          color: #d1c6b6;
          margin-bottom: 20px;
          font-size: 16px;
        }
        .van-field {
          background-color: #343638;
          border-radius: 8px;
          .van-field__label {
            color: #ffffff;
          }
          .van-field__value {
            .van-field__control {
              color: #ffffff;
            }
          }
        }
        .btnBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          .btnS {
            width: 47%;
            padding: 10px 0;
            text-align: center;
            color: #ffffff;
            border-radius: 6px;
          }
          .canBtn {
            background-color: #848484;
          }
          .matchBtn {
            background-color: #bfa16d;
          }
        }
      }
      //.releaseBox {
      //  padding: 15px;
      //  .title {
      //    color: #ffffff;
      //    text-align: center;
      //    margin-bottom: 30px;
      //    font-weight: bold;
      //    font-size: 18px;
      //  }
      //  .van-field {
      //    background-color: #343638;
      //    border-radius: 8px;
      //    .van-field__label {
      //      color: #ffffff;
      //    }
      //    .van-field__value {
      //      .van-field__control {
      //        color: #ffffff;
      //      }
      //    }
      //  }
      //  .btnBox {
      //    display: flex;
      //    align-items: center;
      //    justify-content: space-between;
      //    margin-top: 20px;
      //    .btnS {
      //      width: 47%;
      //      padding: 10px 0;
      //      text-align: center;
      //      color: #ffffff;
      //      border-radius: 6px;
      //    }
      //    .canBtn {
      //      background-color: #848484;
      //    }
      //    .matchBtn {
      //      background-color: #bfa16d;
      //    }
      //  }
      //}
    }
  }
</style>
