<template>
  <van-uploader v-model="list" :accept="accept" :after-read="afterRead" :before-delete="beforeDelete" :before-read="beforeRead" :capture="capture" :deletable="deletable"
                :disabled="disabled" :imageFit="imageFit" :lazyLoad="lazyLoad" :maxCount="maxCount" :maxSize="maxSize" :multiple="multiple" :previewFullImage="previewFullImage"
                :previewOptions="previewOptions" :previewSize="previewSize" :readonly="readonly" :resultType="resultType" :showUpload="showUpload" :uploadText="uploadText">
    <slot/>
  </van-uploader>
</template>
<script>
// https://vant-contrib.gitee.io/vant/v2/#/zh-CN/uploader#api
export default {
  props: [
    "fileList",
    "accept",
    "previewSize",
    "previewFullImage",
    "previewOptions",
    "multiple",
    "disabled",
    "readonly",
    "deletable",
    "showUpload",
    "lazyLoad",
    "capture",
    "afterRead",
    "beforeRead",
    "beforeDelete",
    "maxSize",
    "maxCount",
    "resultType",
    "uploadText",
    "imageFit",
    "uploadIcon",
  ],
  computed: {
    list: {
      get: function () {
        return this.fileList
      },
      set: function () {
      },
    },
  },
}
</script>
