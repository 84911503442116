<template>
  <div>
    <van-list v-model="loading" :finished="finished" :finished-text="finishedText" :immediate-check="immediateCheck" :offset="offset" @load="onLoad">
      <slot></slot>
    </van-list>
    <go-top round/>
  </div>
</template>
<script>
// https://vant-contrib.gitee.io/vant/v2/#/zh-CN/list#api
export default {
  props: {
    //接口api
    api: {
      type: String,
      default: "",
    },
    //其他接口传值
    sendData: {
      type: Object,
      default: () => ({}),
    },
    //接口返回值
    response: {
      type: String,
      default: "data",
    },
    offset: {
      type: [String, Number],
      default: 50,
    },
    finishedText: {
      type: String,
      default: "",
    },
    immediateCheck: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  methods: {
    // 分页加载数据
    onLoad() {
      let page = this.page++
      this.$get({
        url: this.api,
        data: {
          page: page,
          ...this.sendData,
        },
        success: (res) => {
          if (!res.data || res.data[this.response].length == 0) {
            this.finished = true
          } else {
            let list = res.data[this.response]
            this.list = [...this.list, ...list]
          }
          this.$emit("changelist", res.data[this.response])
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>
