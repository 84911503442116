import { render, staticRenderFns } from "./subReg.vue?vue&type=template&id=2b8a6140&scoped=true"
import script from "./subReg.vue?vue&type=script&lang=js"
export * from "./subReg.vue?vue&type=script&lang=js"
import style0 from "./subReg.vue?vue&type=style&index=0&id=2b8a6140&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b8a6140",
  null
  
)

export default component.exports