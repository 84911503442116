<template>
  <div class="noticeListBox">
    <nav-bar bg-color="#2c2e2f" left-arrow left-color="#fff" :title="$t('等待排队配股')" title-color="#fff" />
    <van-search v-model="show_id" show-action :placeholder="$t('请输入搜索ID')" @search="onSearch">
      <template #action>
        <div @click="onSearch">{{ $t('搜索') }}</div>
      </template>
    </van-search>
    <div class="listBox">
      <div v-for="(item, index) in list" :key="index" class="noticeBox">
        <p class="btmTime">
          <span>{{ $t('编号') }}：{{ item.no }}</span>
          {{ $t('金额') }}：{{ item.money }}
        </p>
        <p class="topText">ID：{{ item?.member?.show_id }} <span>{{ item.created_at }}</span> </p>
      </div>
    </div>
    <div class="footbox">
      <div class="page">
        <van-button size="small" type="info" :disabled="page == 1" @click="changelist(1)">{{ $t('首页') }}</van-button>
        <van-pagination v-model="page" :total-items="info.total" :show-page-size="3" force-ellipses @change="getlist" />
        <van-button size="small" type="info" :disabled="page == info.last_page" @click="changelist(info.last_page)">
          {{ $t('尾页') }}</van-button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {},
        list: [],
        page: 1,
        show_id: '',
      }
    },
    methods: {
      getlist() {
        this.$get({
          url: `/issueRights/issueList`,
          data: {
            page: this.page,
            show_id: this.show_id,
          },
          loading: true,
          success: (res) => {
            this.info = res.data
            this.list = res.data.data
          },
          tip: () => { }
        })
      },
      onSearch() {
        this.changelist()
      },
      changelist(page) {
        this.page = page
        this.getlist()
      }
    },
    created() {
      this.getlist()
    },
    mounted() { },
  }
</script>

<style lang="less" scoped>
  .noticeListBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    :deep(.van-search) {
      width: 100%;
      padding: 0 10px 0 15px;
      background-color: #2c2e2f;
      .van-search__content {
        border-radius: 5px;
      }
      .van-search__action {
        color: #ffffff;
      }
      .van-search__action:active {
        background: #898e91;
        border-radius: 5px;
      }
    }
    .listBox {
      width: 100%;
      padding: 15px 15px 100px;
      display: flex;
      flex-direction: column;
      .noticeBox {
        position: relative;
        background-color: #343638;
        padding: 15px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 5px 0;
        margin-bottom: 10px;
        .topText {
          font-weight: bold;
          font-size: 16px;
          color: #ffffff;
          span {
            margin-left: 20px;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .content {
          font-size: 14px;
          color: #ffffff;
        }
        .btmTime {
          font-size: 13px;
          color: #fff;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          span {
            min-width: 30%;
          }
        }
        .dot {
          background-color: #e6d1af;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: absolute;
          right: 20px;
        }
      }
    }
    .footbox {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: #2c2e2f;
      .page {
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/.van-button {
          height: 10.7vw;
        }
        /deep/.van-pagination {
          flex: 1
        }
      }
    }
  }
</style>
