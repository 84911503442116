<template>
  <div class="download">
    <nav-bar :placeholder="false" :title="$t('下载') + ' ' + 'APP'" bg-color="" left-arrow @click-left="$router.go(-1)" />
    <div v-if="!isWeixin">
      <div class="header">
        <div class="logobox">
          <img alt="" src="@/assets/img/logo.png" />
        </div>
      </div>
      <div class="mainbox">
        <van-button icon="down" type="info"
          @click="download('https://jiaying.s3.ap-northeast-1.amazonaws.com/LT.apk')">{{ $t('下载') }}</van-button>
      </div>
    </div>

  </div>
</template>
<script>
  export default {
    data() {
      return {
        data: {},
        isWeixin: false,
      }
    },
    created() {
      // this.getdata()
    },
    mounted() {
      // this.isWeixin = /micromessenger/.test(window.navigator.userAgent.toLowerCase())
    },
    methods: {
      // getdata() {
      //   this.$get({
      //     url: "/app/entry/getEntry",
      //     success: (res) => {
      //       this.$toast.clear()
      //       this.data = res.data
      //     },
      //     tip: () => {
      //     },
      //   })
      // },
      download(href) {
        window.open(href)

        // 创建隐藏的可下载链接
        //   var eleLink = document.createElement("a")
        //   eleLink.setAttribute("href", href)
        //   eleLink.setAttribute("download", name)
        //   eleLink.style.display = "none"
        //   // 触发点击
        //   document.body.appendChild(eleLink)
        //   eleLink.click()
        //   // 然后移除
        //   document.body.removeChild(eleLink)
      },
    },
  }
</script>

<style lang="less" scoped>
  .download {
    width: 100%;
    min-height: 100vh;
    //background-color: #2B2E30;
    .header {
      width: 100%;
      padding: 120px 0 10px;
      background: url("../../../assets/img/aac.png") no-repeat;
      background-size: 100% 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      .logobox {
        width: 150px;
        height: 150px;
        box-shadow: 0px 3px 6px rgba(6, 101, 228, 0.08);
        background: #ffffff;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        >img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .mainbox {
      width: 100%;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /deep/ .van-button {
        width: 40%;
        margin: 30px 0;
        border-radius: 10px;
      }
      .tit {
        font-size: 14px;
        color: #999999;
      }
      .qrcodes {
        padding: 10px;
      }
    }
  }
</style>
