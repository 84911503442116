<template>
  <div v-cloak id="app">
    <transition mode="out-in" name="fade">
      <router-view v-if="show"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      show: true
    };
  },
  methods: {},
};
</script>

<style lang="less">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// 修改van-toast--loading颜色 用就打开注释改 否则忽略
// .van-toast--loading {
//   box-shadow: 0px 1vw 3vw rgba(0, 0, 0, 0.16);
//   background-color: #fff !important;
//   .van-toast__loading {
//     color: #333;
//   }
//   .van-toast__text {
//     color: #333;
//   }
// }
</style>
