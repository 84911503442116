<template>
  <div class="exListBox">
    <nav-bar :title="$t('兑换记录')" bg-color="transparent" left-arrow left-color="#fff" title-color="#fff" />
    <div class="listBox">
      <page-list :finished-text="$t('没有更多了')" :offset="0" api="/exchange/list" :sendData="{ type }"
        @changelist="(data) => { list = [...list, ...data] }">
        <div v-for="(item, index) in list" :key="index" class="list">
          <p class="top">
            <span class="left" v-if="item.type == 1">{{ $t('兑换注册币') }}</span>
            <span class="left" v-if="item.type == 2">{{ $t('兑换子币') }}</span>
            <span class="right">{{ item.actual }}</span>
          </p>
          <p class="time">{{ item.created_at }}</p>
        </div>
      </page-list>
    </div>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        list: [],
        type: this.urlQuery().type,//1:权益币兑换注册币 2 注册币兑换子币
      }
    },
    computed: {},
    watch: {},
    methods: {},
    created() {
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .exListBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px;
    .listBox {
      .list {
        padding: 10px;
        border-radius: 10px;
        background-color: #343638;
        margin-bottom: 10px;
        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5px;
          .left {
            color: #ffffff;
            font-weight: bold;
          }
          .right {
            color: #dbd3b5;
          }
        }
        .time {
          font-size: 10px;
          color: #6b6d6f;
        }
      }
    }
  }
</style>
