<template>
  <div class="balanceListBox">
    <nav-bar :title="$t(title[type])" bg-color="linear-gradient( 133deg, #DBC397 0%, #BA9A66 100%)" left-arrow
      left-color="#000" title-color="#000" />
    <div>
      <div class="msgBox">
        <div class="topBox">
          <p class="title">{{ $t(title[type]) }}</p>
          <p class="price">
            {{ price || 0 }}
            <span v-if="type === '0'" class="doer"> ≈ ${{ balance_amount }}</span>
          </p>
          <p class="btnBox1" v-if="type == 1 || type == 3 || type == 4">
            <span v-if="type == 1" class="btn" @click="router(`/exChange?type=1`)">{{ $t('兑换') }}</span>
            <span v-if="type == 3" class="btn" @click="router(`/exChange?type=2`)">{{ $t('兑换') }}</span>
            <span v-if="type == 3" class="btn" @click="router('/transfer?type=1')">{{ $t('转赠') }}</span>
            <!-- <span v-if="type == 4" class="btn" @click="router('/transfer?type=2')">{{ $t('转赠') }}</span> -->
            <!--<span class="btn" @click="router('/withdraw')">{{ $t('提现') }}</span>-->
          </p>
          <!-- <p class="btnBox1" v-if="type == 4">
            <span class="btn" @click="router('/subReg')">{{ $t('开通子账号') }}</span>
          </p> -->
        </div>
        <div class="listBox">
          <page-list :api="`/balanceLog/getList?balance_field=${equ[type]}`" :finished-text="$t('没有更多了')" :offset="0"
            @changelist="(data) => { list = [...list, ...data] }">
            <div v-for="(item, index) in list" :key="index" class="list">
              <p class="top">
                <span class="left">{{ item.remark }}</span>
                <span class="right">{{ item.amount }}</span>
              </p>
              <p class="time">{{ item.created_at }}</p>
            </div>
          </page-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        type: "",
        price: "",
        balance_amount: "",
        title: ["股权余额", "权益币余额", "铸造币余额", "注册币余额", "子币余额", "燃烧币余额"],
        equ: ["balance", "equity", "mint", "coin", "sub_coin", "burning_coin"],
        list: [],
      }
    },
    computed: {},
    watch: {},
    methods: {
      // 获取信息
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          loading: true,
          success: (res) => {
            this.price = res.data[['balance', 'equity', 'mint', 'coin', "sub_coin", "burning_coin"][this.type]]
            this.balance_amount = res.data.balance_amount
          },
          tip: () => {
          }
        })
      },
    },
    created() {
      this.type = this.$route.query.type
      this.getMemberInfo()
      // this.price = this.$route.query.price
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .balanceListBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    .msgBox {
      position: relative;
      top: -50px;
      .topBox {
        background: linear-gradient(133deg, #DBC397 0%, #BA9A66 100%);
        padding: 80px 30px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #5e4024;
        .title {
          font-size: 12px;
        }
        .price {
          font-size: 24px;
          font-weight: bold;
          display: flex;
          flex-direction: column;
          .doer {
            font-weight: 400;
            font-size: 18px;
          }
        }
        .btnBox1 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin-top: 20px;
          .btn {
            color: #ffffff;
            background-color: #583f25;
            border-radius: 100px;
            font-weight: bold;
            padding: 10px 50px;
          }
        }
      }
      .listBox {
        padding: 15px;
        .list {
          padding: 10px;
          border-radius: 10px;
          background-color: #343638;
          margin-bottom: 10px;
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;
            .left {
              color: #ffffff;
              font-weight: bold;
            }
            .right {
              color: #dbd3b5;
            }
          }
          .time {
            font-size: 10px;
            color: #6b6d6f;
          }
        }
      }
    }
  }
</style>
