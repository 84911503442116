<template>
  <div class="splitListBox">
    <nav-bar :title="$t('拆分记录')" bg-color="#2c2e2f" left-arrow left-color="#fff" title-color="#fff" />
    <div>
      <page-list :finished-text="$t('没有更多了')" :offset="0" api="/breakLog/logList"
        @changelist="(data) => { list = [...list, ...data] }">
        <div v-for="(item, index) in list" :key="index" class="list">
          <p class="ls">
            <span>{{ $t('拆分次数') }} ： </span>
            <span>{{ $t('第') + " " + item.times + " " + $t('次拆分') }}</span>
          </p>
          <p class="ls">
            <span>{{ $t('拆分倍数') }} ：</span>
            <span>{{ item.multiple + " " + $t('倍') }}</span>
          </p>
          <p class="ls">
            <span>{{ $t('拆分时间') }} ： </span>
            <span>{{ item.created_at }}</span>
          </p>
        </div>
      </page-list>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        list: [],
      }
    },
    computed: {},
    watch: {},
    methods: {},
    created() {
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .splitListBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px;
    .list {
      padding: 10px;
      display: flex;
      flex-direction: column;
      background-color: #343638;
      color: #999999;
      border-radius: 8px;
      margin-bottom: 10px;
      .ls {
        display: flex;
        justify-content: space-between;
      }
    }
  }
</style>
