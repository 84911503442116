<template>
  <div class="aboutUsBox">
    <nav-bar :title="$t('关于我们')" bg-color="#2c2e2f" left-arrow left-color="#fff" title-color="#fff"/>
    <div class="text" v-html="text"/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: "",
    }
  },
  computed: {},
  watch: {},
  methods: {
    agreement() {
      this.$get({
        url: `/agreement?type=3`,
        loading: true,
        success: (res) => {
          this.text = res.data.content
        },
        tip: () => {
        }
      })
    },
  },
  created() {
    this.agreement()
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.aboutUsBox {
  width: 100%;
  min-height: 100vh;
  background-color: #2c2e2f;
  padding: 20px 15px;

  .text {
    color: #ffffff;
  }
}
</style>
