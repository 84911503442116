<template>
  <div class="balanceListBox">
    <nav-bar :title="$t('子账号')" bg-color="linear-gradient( 133deg, #DBC397 0%, #BA9A66 100%)" left-arrow
      left-color="#000" title-color="#000" />
    <div>
      <div class="msgBox">
        <div class="topBox" @click="router('/balanceList', { type: 5 })">
          <p class="title">{{ $t('燃烧币余额') }}</p>
          <p class="price"> {{ price || 0 }}</p>
          <p class="btnBox1">
            <span class="btn" @click="router('/subReg')">{{ $t('开通子账号') }}</span>
          </p>
        </div>
        <div class="listBox">
          <page-list :api="`/member/subList`" :finished-text="$t('没有更多了')" :offset="0"
            @changelist="(data) => { list = [...list, ...data] }">
            <div v-for="(item, index) in list" :key="index" class="list"
              @click="router('/walletlist', { id: item.id })">
              <p class="top">
                <span class="left">{{ item.account }}</span>
                <!-- <span class="right">{{ item.show_id }}</span> -->
              </p>
              <p class="time">{{ $t('股权余额') }}：{{ item.balance }}</p>
            </div>
          </page-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        price: "",
        list: [],
      }
    },
    computed: {},
    watch: {},
    methods: {
      // 获取信息
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          loading: true,
          success: (res) => {
            this.price = res.data.burning_coin
          },
          tip: () => {
          }
        })
      },
    },
    created() {
      this.getMemberInfo()
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .balanceListBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    .msgBox {
      position: relative;
      top: -50px;
      .topBox {
        background: linear-gradient(133deg, #DBC397 0%, #BA9A66 100%);
        padding: 80px 30px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #5e4024;
        .title {
          font-size: 12px;
        }
        .price {
          font-size: 24px;
          font-weight: bold;
          display: flex;
          flex-direction: column;
          .doer {
            font-weight: 400;
            font-size: 18px;
          }
        }
        .btnBox1 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin-top: 20px;
          .btn {
            color: #ffffff;
            background-color: #583f25;
            border-radius: 100px;
            font-weight: bold;
            padding: 10px 50px;
          }
        }
      }
      .listBox {
        padding: 15px;
        .list {
          padding: 10px;
          border-radius: 10px;
          background-color: #343638;
          margin-bottom: 10px;
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;
            .left {
              color: #ffffff;
              font-weight: bold;
            }
            .right {
              color: #ccc;
            }
          }
          .time {
            font-size: 14px;
            color: #fff;
            font-weight: 550;
          }
        }
      }
    }
  }
</style>
