<template>
  <div class="noticeListBox">
    <nav-bar bg-color="#2c2e2f" left-arrow left-color="#fff" :title="$t('奖金池分红')" title-color="#fff" />
    <div class="listBox">
      <page-list :offset="0" api="/bonusPool/bonusMemberList" :finished-text="$t('没有更多了')"
        @changelist="(data) => { list = [...list, ...data] }">
        <div v-for="(item, index) in list" :key="index" class="noticeBox">
          <p class="topText">ID：{{ item.show_id }}</p>
          <p class="btmTime">{{ item.account }}</p>
        </div>
      </page-list>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        list: [],
      }
    },
    computed: {},
    watch: {},
    methods: {},
    created() {
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .noticeListBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px;
    .listBox {
      display: flex;
      flex-direction: column;
      .noticeBox {
        position: relative;
        background-color: #343638;
        padding: 15px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 5px 0;
        margin-bottom: 10px;
        .topText {
          font-weight: bold;
          font-size: 16px;
          color: #ffffff;
        }
        .content {
          font-size: 14px;
          color: #ffffff;
        }
        .btmTime {
          font-size: 13px;
          color: #fff;
        }
        .dot {
          background-color: #e6d1af;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: absolute;
          right: 20px;
        }
      }
    }
  }
</style>
