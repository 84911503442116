<template>
  <div class="regListBox">
    <nav-bar :title="$t('注册记录')" bg-color="#2c2e2f" left-arrow left-color="#fff" title-color="#fff" />
    <div class="list">
      <page-list :finished-text="$t('没有更多了')" :offset="0" api="/regLog/list"
        @changelist="(data) => { list = [...list, ...data] }">
        <div v-for="(item, index) in list" :key="index" class="listBox">
          <div class="top pad">
            <p class="msgList">
              <span class="leftTxt">{{ $t('会员编号') }}：</span>
              <span class="msg">{{ item.member?.show_id || '' }}</span>
            </p>
            <p class="msgList">
              <span class="leftTxt">{{ $t('会员邮箱') }}：</span>
              <span class="msg">{{ item.account }}</span>
            </p>
            <p class="msgList">
              <span class="leftTxt">{{ $t('扣除币数') }}：</span>
              <span class="msg pr">{{ item.amount }}</span>
            </p>
            <p class="msgList">
              <span class="leftTxt">{{ $t('安置人编号') }}：</span>
              <span class="msg">{{ item.parent_node_account }}</span>
            </p>
            <p class="msgList">
              <span class="leftTxt">{{ $t('推荐人编号') }}：</span>
              <span class="msg">{{ item.parent_account }}</span>
            </p>
          </div>
          <span class="line" />
          <div class="btm pad">{{ $t('注册时间') }}：{{ item.created_at }}</div>
        </div>
      </page-list>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        list: [],
      }
    },
    computed: {},
    watch: {},
    methods: {},
    created() {
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .regListBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px;
    .listBox {
      background-color: #343638;
      border-radius: 10px;
      margin-bottom: 10px;
      .top {
        display: flex;
        flex-direction: column;
        gap: 5px 0;
        .msgList {
          display: flex;
          align-items: center;
          gap: 0 5px;
          color: #ffffff;
          .pr {
            color: #c6beaf;
          }
        }
      }
      .pad {
        padding: 15px;
      }
      .line {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #434547;
      }
      .btm {
        color: #999999;
      }
    }
  }
</style>
