<template>
  <div class="balanceListBox">
    <nav-bar :title="$t('子账号股权数')" bg-color="linear-gradient( 133deg, #DBC397 0%, #BA9A66 100%)" left-arrow
      left-color="#000" title-color="#000" />
    <div>
      <div class="msgBox">
        <div class="topBox"></div>
        <div class="listBox">
          <page-list :api="`/balanceLog/getSubList?id=${id}`" :finished-text="$t('没有更多了')" :offset="0"
            @changelist="(data) => { list = [...list, ...data] }">
            <div v-for="(item, index) in list" :key="index" class="list">
              <p class="top">
                <span class="left">{{ item.remark }}</span>
                <span class="right">{{ item.amount }}</span>
              </p>
              <p class="time">{{ item.created_at }}</p>
            </div>
          </page-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        id: this.urlQuery().id,
        list: [],
      }
    },
    methods: {
      // 获取信息
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          loading: true,
          success: (res) => {
            this.price = res.data.sub_balance
          },
          tip: () => {
          }
        })
      },
    },
    created() {
      // this.getMemberInfo()
    },
  }
</script>

<style lang="less" scoped>
  .balanceListBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    .msgBox {
      .listBox {
        padding: 15px;
        .list {
          padding: 10px;
          border-radius: 10px;
          background-color: #343638;
          margin-bottom: 10px;
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;
            .left {
              color: #ffffff;
              font-weight: bold;
            }
            .right {
              color: #dbd3b5;
            }
          }
          .time {
            font-size: 10px;
            color: #6b6d6f;
          }
        }
      }
    }
  }
</style>
