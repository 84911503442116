<template>
  <div class="noticeListBox">
    <nav-bar bg-color="#2c2e2f" left-arrow left-color="#fff" :title="$t('反馈记录')" title-color="#fff" />
    <div class="listBox">
      <page-list :offset="0" api="/feedback/feedbackList" :finished-text="$t('没有更多了')"
        @changelist="(data) => { list = [...list, ...data] }">
        <div v-for="(item, index) in list" :key="index" class="noticeBox">
          <p class="topText">{{ item.content }}
            <span style="font-size: 12px;" v-if="item.images" @click="lookImage(item)">[{{ $t('查看图片') }}]</span>
          </p>
          <p class="btmTime">{{ item.created_at }}</p>
          <p class="topText" v-if="item.status == 1"> {{ $t('回复') }}：{{ item.reply }}</p>
          <p class="btmTime" v-if="item.status == 1"> {{ $t('回复时间') }}：{{ item.reply_time }}</p>
        </div>
      </page-list>
    </div>
  </div>
</template>
<script>
  import { ImagePreview } from 'vant';
  export default {
    data() {
      return {
        list: [],
      }
    },
    computed: {},
    watch: {},
    methods: {
      lookImage(data) {
        let imgs = data.images.split(';')
        let images = []
        imgs.forEach(item => {
          images.push(`${window.origin}${item}`)
        })
        ImagePreview(images)
      }
    },
    created() {
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .noticeListBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px;
    .listBox {
      display: flex;
      flex-direction: column;
      .noticeBox {
        position: relative;
        background-color: #343638;
        padding: 15px 15px 5px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 5px 0;
        margin-bottom: 10px;
        .topText {
          font-weight: bold;
          font-size: 16px;
          color: #ffffff;
        }
        .content {
          font-size: 14px;
          color: #ffffff;
        }
        .btmTime {
          font-size: 13px;
          color: #fff;
          margin-bottom: 10px;
        }
        .dot {
          background-color: #e6d1af;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: absolute;
          right: 20px;
        }
      }
    }
  }
</style>
