<template>
  <div class="regVipBox">
    <nav-bar :right-text="$t('注册记录')" :title="$t('注册会员')" bg-color="#d5ba8d" left-arrow left-color="#000"
      title-color="#000" @click-right="router('/regList')" />
    <div class="vipBox">
      <div class="topBox">
        <div class="left">
          <p class="titName">{{ $t('我的注册币') }}</p>
          <p class="price">
            <img alt="" class="priceImg" src="@/assets/img/mine11.png">
            <span class="pr">{{ myDate?.coin || 0 }}</span>
          </p>
        </div>
        <img alt="" class="rightImg" src="@/assets/img/mine12.png">
      </div>
      <div class="btmBox">
        <div class="levelBox">
          <p class="checkLevel" @click="fieldShow = true">
            <span class="left">{{ levelTxt || $t("选择等级") }}</span>
            <van-icon color="#fff" name="arrow-down" size="20px" />
          </p>
          <p class="deduct"> {{ $t('需要扣除') }} {{ duct }} {{ $t('注册币') }} </p>
          <span class="line" />
          <van-field v-model="account" :border="false" :label="$t('会员邮箱')" :placeholder="$t('请输入会员邮箱地址')" />
          <span class="line" />
          <van-field v-model="parent_account" :border="false" :label="$t('推荐人')" :placeholder="$t('请输入推荐人编号')" />
          <span class="line" />
          <van-field v-model="parent_node_account" :border="false" :label="$t('安置人')" :placeholder="$t('请输入安置人编号')" />
          <span class="line" />
          <van-field v-model="node_position_txt" :border="false" :label="$t('安置位置')" :placeholder="$t('请选择安置位置')"
            readonly right-icon="arrow-down" @click="positionShow = true" />
        </div>
        <div class="btnBox">
          <p class="btn" @click="register">{{ $t('开通') }}</p>
        </div>
      </div>
    </div>
    <van-popup v-model="fieldShow" :close-on-click-overlay="false" position="bottom">
      <van-picker :columns="columns" :title="$t('选择等级')" show-toolbar @cancel="onCancel" @confirm="onConfirm" />
    </van-popup>
    <van-popup v-model="positionShow" :close-on-click-overlay="false" position="bottom">
      <van-picker :columns="positionColumns" :title="$t('安置位置')" show-toolbar @cancel="onCancel"
        @confirm="positionFirm" />
    </van-popup>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        myDate: {},
        levelTxt: "",
        level_id: "",
        account: "",
        parent_account: "",
        parent_node_account: "",
        node_position: "",
        node_position_txt: "",
        fieldShow: false,
        columns: [],
        duct: 0,
        positionShow: false,
        positionColumns: [
          { id: 1, text: this.$t("左区"), node: "left" },
          { id: 2, text: this.$t("右区"), node: "right" },
        ]
      }
    },
    computed: {},
    watch: {},
    methods: {
      // 获取会员信息
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          loading: true,
          success: (res) => {
            this.myDate = res.data
            this.parent_account = res.data.show_id
          },
          tip: () => {
          }
        })
      },
      // 获取会员等级信息
      getLevelList() {
        this.$get({
          url: `/memberLevel/getLevelList`,
          loading: true,
          success: (res) => {
            this.getMemberInfo()
            res.data.map(item => {
              this.columns.push({
                id: item.id,
                text: item.level_name,
                price: item.amount,
              })
            })
          },
          tip: () => {
          }
        })
      },
      // 注册会员
      register() {
        if (!this.level_id) return this.$toast(this.$t("请选择注册等级"))
        if (!this.account) return this.$toast(this.$t("请输入会员邮箱"))
        if (!this.parent_account) return this.$toast(this.$t("请输入推荐人编号"))
        if (!this.parent_node_account) return this.$toast(this.$t("请输入安置人编号"))
        if (!this.node_position) return this.$toast(this.$t("请选择安置位置"))
        this.$post({
          url: `/register`,
          loading: true,
          data: {
            level_id: this.level_id,
            account: this.account,
            parent_account: this.parent_account,
            parent_node_account: this.parent_node_account,
            node_position: this.node_position,
          },
          success: (res) => {
            this.$toast({
              message: res.message,
              onClose: () => {
                this.getMemberInfo()
              }
            })

          },
          tip: () => {
          }
        })
      },
      onConfirm(e) {
        this.levelTxt = e.text
        this.level_id = e.id
        this.duct = e.price
        this.fieldShow = false
      },
      onCancel() {
        this.fieldShow = false
        this.positionShow = false
      },
      positionFirm(e) {
        this.node_position = e.node
        this.node_position_txt = e.text
        this.positionShow = false
      },
    },
    created() {
      this.getLevelList()
    },
    mounted() {
    },
  }
</script>

<style lang="less" scoped>
  .regVipBox {
    min-height: 100vh;
    background-color: #2c2e2f;
    width: 100%;
    .topBox {
      padding: 0 20px 30px;
      background-color: #d5ba8d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        .titName {
          color: #7f6338;
        }
        .price {
          display: flex;
          align-items: center;
          gap: 0 10px;
          .priceImg {
            width: 25px;
            height: 25px;
            border-radius: 50%;
          }
          .pr {
            font-size: 24px;
            font-weight: bold;
            color: #583f20;
          }
        }
      }
      .rightImg {
        width: 166px;
        height: 166px;
      }
    }
    .btmBox {
      padding: 0 10px 30px;
      background-color: #2c2e2f;
      .levelBox {
        background-color: #343638;
        padding: 20px 20px 0 20px;
        border-radius: 10px;
        transform: translateY(-50px);
        .checkLevel {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #434547;
          padding: 15px 10px;
          border-radius: 8px;
          .left {
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
          }
        }
        .deduct {
          color: #a29f99;
          font-size: 12px;
          margin: 10px 0;
        }
        ::v-deep .van-field {
          background-color: #343638;
          padding-left: 0;
          padding-right: 0;
          .van-field__label {
            color: #ffffff;
          }
          .van-field__value {
            .van-field__control {
              color: #ffffff;
            }
          }
        }
        .line {
          display: block;
          width: 100%;
          height: 1px;
          background-color: #434547;
        }
      }
      .btnBox {
        padding: 10px 15px;
        .btn {
          background-color: #b99b66;
          color: #ffffff;
          border-radius: 10px;
          padding: 10px 0;
          text-align: center;
        }
      }
    }
  }
</style>
