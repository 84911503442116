<template>
  <div class="mineBox">
    <!--    <div class="mineUserMsg" @click="router('/mineMsg')">-->
    <div class="mineUserMsg">
      <p class="left">
        <span class="top">
          <span class="name">{{ userDate.nickname }}</span>
          <span v-if="userDate.level_id != 0" class="levelImg">{{ userDate?.level?.level_name }}</span>
        </span>
        <span class="btm">
          <span class="refNum">{{ $t('编号') }}：{{ userDate.show_id }}</span>
          <img alt="" class="copyImg" src="@/assets/img/mine1.png" @click="copyDate(userDate.show_id)">
        </span>
      </p>
      <p class="right">
        <img :src="httpPath + userDate.head_img" alt="" class="userImg">
        <!--        <van-icon color="#999" name="arrow"/>-->
      </p>
    </div>
    <div class="assetBox">
      <p class="txt">{{ $t('我的资产') }}</p>
      <p class="balanceList">
        <span class="balanceBox" @click="router('/balanceList', { type: 0 })">
          <span class="top">{{ $t('股权余额') }}</span>
          <span class="num">{{ userDate.balance || 0 }}</span>
        </span>
        <span class="balanceBox" @click="router('/balanceList', { type: 1 })">
          <span class="top">{{ $t('权益币余额') }}</span>
          <span class="num">{{ userDate.equity || 0 }}</span>
        </span>
        <span class="balanceBox" @click="router('/balanceList', { type: 3 })">
          <span class="top">{{ $t('注册币余额') }}</span>
          <span class="num">{{ userDate.coin || 0 }}</span>
        </span>
        <span class="balanceBox" @click="router('/balanceList', { type: 2 })">
          <span class="top">{{ $t('铸造币余额') }}</span>
          <span class="num">{{ userDate.mint || 0 }}</span>
        </span>
        <span class="balanceBox" @click="router('/subList')">
          <span class="top">{{ $t('子账号') }}</span>
        </span>
        <span class="balanceBox" @click="router('/balanceList', { type: 4 })">
          <span class="top">{{ $t('子币余额') }}</span>
          <span class="num">{{ userDate.sub_coin || 0 }}</span>
        </span>
      </p>
    </div>
    <div class="btnBox">
      <p class="btnList" @click="router('/levelUp')">
        <span class="left">
          <img alt="" class="btnImg" src="@/assets/img/mine3.png">
          <span class="txt">{{ $t('权益等级升级') }}</span>
        </span>
        <van-icon color="#fff" name="arrow" />
      </p>
      <p class="btnList" @click="router('/splitList')">
        <span class="left">
          <img alt="" class="btnImg" src="@/assets/img/mine13.png">
          <span class="txt">{{ $t('拆分记录') }}</span>
        </span>
        <van-icon color="#fff" name="arrow" />
      </p>
      <!--      <p class="btnList" @click="router('/tradeList')">-->
      <!--        <span class="left">-->
      <!--          <img alt="" class="btnImg" src="@/assets/img/mine4.png">-->
      <!--          <span class="txt">{{ $t('交易记录') }}</span>-->
      <!--        </span>-->
      <!--        <van-icon color="#fff" name="arrow"/>-->
      <!--      </p>-->
      <p class="btnList" @click="router('/regVip')">
        <span class="left">
          <img alt="" class="btnImg" src="@/assets/img/mine5.png">
          <span class="txt">{{ $t('注册会员') }}</span>
        </span>
        <van-icon color="#fff" name="arrow" />
      </p>
      <p class="btnList" @click="router('/walletAddress')">
        <span class="left">
          <img alt="" class="btnImg" src="@/assets/img/mine6.png">
          <span class="txt">{{ $t('钱包地址') }}</span>
        </span>
        <van-icon color="#fff" name="arrow" />
      </p>

      <p class="btnList" @click="router('/language')">
        <span class="left">
          <img alt="" class="btnImg" src="@/assets/img/mine8.png">
          <span class="txt">{{ $t('语言切换') }}</span>
        </span>
        <van-icon color="#fff" name="arrow" />
      </p>
      <p class="btnList" @click="router('/aboutUs')">
        <span class="left">
          <img alt="" class="btnImg" src="@/assets/img/mine9.png">
          <span class="txt">{{ $t('关于我们') }}</span>
        </span>
        <van-icon color="#fff" name="arrow" />
      </p>
      <p class="btnList" @click="router('/mineMsg')">
        <span class="left">
          <img alt="" class="btnImg" src="@/assets/img/mine10.png">
          <span class="txt">{{ $t('设置') }}</span>
        </span>
        <van-icon color="#fff" name="arrow" />
      </p>

      <p class="btnList" @click="router('/feedback')">
        <span class="left">
          <img alt="" class="btnImg" src="@/assets/img/kf.png">
          <span class="txt">{{ $t('客服') }}</span>
        </span>
        <van-icon color="#fff" name="arrow" />
      </p>

    </div>
    <div class="btnBox">
      <p class="btn" @click="logout()">{{ $t('退出登录') }}</p>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        userDate: {},
      }
    },
    created() {
      this.getMemberInfo()
    },
    methods: {
      getMemberInfo() {
        this.$get({
          url: `/member/getMemberInfo`,
          // loading: true,
          success: (res) => {
            this.userDate = res.data
          },
          tip: () => {
          }
        })
      },
      copyDate(id) {
        this.$copyText(JSON.stringify(id)).then(() => {
          this.$toast(this.$t('复制成功'))
        }, function () {
          this.$toast(this.$t('复制失败'))
        })
      },
      logout() {
        this.$get({
          url: `/logout`,
          loading: true,
          success: (res) => {
            this.$toast({
              message: res.message,
              onClose: () => {
                localStorage.clear()
                this.goReplace("/login")
              },
            })
          },
          tip: () => {
          }
        })
      },
    },
  }
</script>
<style lang="less" scoped>
  .mineBox {
    width: 100%;
    min-height: 100vh;
    background-color: #2c2e2f;
    padding: 15px 15px 70px;
    .mineUserMsg {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .top {
          display: flex;
          align-items: center;
          gap: 0 10px;
          .name {
            color: #ffffff;
            font-size: 16px;
            font-weight: bold;
          }
          .levelImg {
            width: 62px;
            height: 30px;
            background-image: url("../../assets/img/team4.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            text-align: end;
            padding: 6px 7px;
          }
        }
        .btm {
          display: flex;
          align-items: center;
          gap: 0 20px;
          .refNum {
            color: #ffffff;
            font-size: 12px;
          }
          .copyImg {
            width: 12px;
            height: 12px;
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        gap: 0 10px;
        .userImg {
          width: 60px;
          height: 60px;
          border: 2px solid #ffffff;
          border-radius: 50%;
        }
      }
    }
    .assetBox {
      margin-top: 20px;
      padding: 10px 15px 5px;
      border-radius: 8px;
      background-image: url("../../assets/img/mine2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .txt {
        color: #8b7248;
        font-size: 12px;
        margin-bottom: 10px;
        font-weight: bold;
      }
      .balanceList {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        .balanceBox {
          min-width: 33%;
          color: #796139;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-weight: bold;
          padding: 0 2px;
          gap: 5px 0;
          margin: 0 0 10px;
          .top {
            width: 100%;
            text-align: center;
            font-size: 14px;
          }
          .num {
            width: 100%;
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }
    .btnBox {
      padding: 15px;
      background-color: #343638;
      border-radius: 8px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 30px 0;
      .btnList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          gap: 0 15px;
          .btnImg {
            width: 20px;
            height: 20px;
          }
          .txt {
            color: #ffffff;
          }
        }
      }
      .btn {
        color: #ffffff;
        text-align: center;
      }
    }
  }
</style>
